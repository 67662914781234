/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, September 2019
 */

import ApiUtils from '../utils/ApiUtils'
import ActionTypes from '../constants/ActionTypes'

const {
  RECEIVE_GET_HOTEL_INTEGRATIONS,
  RECEIVE_GET_HOTEL_INTEGRATION,
  RECEIVE_DELETE_HOTEL_INTEGRATION,
  RECEIVE_GET_HOTEL_VERIFY_INTEGRATION,
  RECEIVE_INTEGRATION_CREDENTIALS_STATUS
} = ActionTypes

export function loadIntegrations(hotelId, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/integrations/configurations`
    ApiUtils.get(true, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_INTEGRATIONS,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function saveIntegration(hotelId, integration, cb) {
  return dispatch => {
    const integrationId = integration.get('id')
    const integrationKey = integration.get('integrationKey')
    const integrationType = integrationKey.toUpperCase()

    const url = integrationId
      ? `/v1/admin/integrations/configurations/${integrationId}`
      : `/v1/admin/hotels/${hotelId}/integrations/configurations`

    const method = integrationId ? 'put' : 'post'
    const params = {
      integration: {
        integration_data: integration.get('integration_data'),
        integration_name: integrationType
      }
    }

    ApiUtils[method](params, url, (statusCode, body) => {
      if (statusCode === 200 || statusCode === 201) {
        dispatch({
          type: RECEIVE_GET_HOTEL_INTEGRATION,
          integrationKey,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function deleteIntegration(hotelId, integrationId, cb) {
  return dispatch => {
    const url = `/v1/admin/integrations/configurations/${integrationId}`
    ApiUtils.delete(true, url, (statusCode, body) => {
      if (statusCode === 204) {
        dispatch({
          type: RECEIVE_DELETE_HOTEL_INTEGRATION,
          integrationId: integrationId,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function verifyIntegration(selectedHotelId, integration, cb) {
  const url = `/v1/admin/hotels/${selectedHotelId}/integration_statuses`
  const params = {
    integration_status: {
      ...integration
    }
  }
  return dispatch => {
    ApiUtils.post(params, url, (statusCode, body) => {
      if (statusCode === 200 || statusCode === 201) {
        dispatch({
          type: RECEIVE_GET_HOTEL_VERIFY_INTEGRATION,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function loadVerifiedIntegrationBySource(selectedHotelId, source, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${selectedHotelId}/integration_statuses/source/${source}`
    ApiUtils.get(true, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_VERIFY_INTEGRATION,
          data: body
        })
      }
      cb && cb(statusCode, body)
    })
  }
}

export function stopVerifiedIntegrationProcess(selectedHotelId, id, params, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${selectedHotelId}/integration_statuses/${id}`
    ApiUtils.put(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_VERIFY_INTEGRATION,
          data: body
        })
      }
      cb && cb(statusCode, body)
    })
  }
}

export function getIntegrationCredentialsStatus(integration, selectedHotelId, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${selectedHotelId}/integrations/test_credentials`
    const params = integration
    ApiUtils.post(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_INTEGRATION_CREDENTIALS_STATUS,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function syncIntegration(selectedHotelId, integration, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${selectedHotelId}/integrations/configurations/sync`
    ApiUtils.post(
      { integration_name: integration.get('integrationKey') },
      url,
      (statusCode, body) => {
        cb && cb(statusCode, body)
      }
    )
  }
}
