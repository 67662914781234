import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { is, fromJS, Map } from 'immutable'
import alertify from 'alertifyjs'
import _ from 'lodash'
import ToggleShowPassword from './ToggleShowPassword'
import {
  loadUserPropertiesPermissions,
  updateUserPropertiesPermissions,
  deleteUserPropertiesPermissions,
  createOrUpdateAccountAdmin,
  updateAccountAdminPassword
} from '../actions/AccountActions'

import Loader from './Loader'
import { updateHotelUser, updateHotelUserRole } from '../actions/HotelActions'

class EditAccountAdmin extends React.Component {
  static propTypes = {
    user: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      user: this.getUserProps(props),
      userPropertiesPermissions: [],
      showPassword: false,
      password: '',
      confirmPassword: ''
    }
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    const { userId } = match && match.params

    dispatch(
      loadUserPropertiesPermissions(userId, (statusCode, body) => {
        if (statusCode === 200) {
          this.setState({ userPropertiesPermissions: body.user_properties_permissions })
        }
      })
    )
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      user: this.getUserProps(nextProps)
    })
  }

  getUserProps = props => {
    const { match, accountAdmins } = props
    const { userId } = match && match.params

    return userId ? accountAdmins.get(userId) : new Map({})
  }

  handleChange = ev => {
    const user = this.state.user.set(ev.target.name, ev.target.value)
    this.setState({ user })
  }

  handleSubmit = ev => {
    ev.preventDefault()

    const { dispatch, match } = this.props
    const { selectedAccountId, userId } = match && match.params
    const { user } = this.state

    this.setState({ isSaving: true, error: null })

    dispatch(
      createOrUpdateAccountAdmin(selectedAccountId, user.toJS(), (statusCode, body) => {
        this.setState({ isSaving: false })
        if (statusCode !== 200 && statusCode !== 201) {
          return this.setState({ error: fromJS(body) })
        }

        alertify.success(`Admin account successfully ${userId ? 'updated' : 'created'}`)
        this.handlePropertiesPermissionUpdate(body.user.id)
      })
    )
  }

  updatePropertiesPermission = hotelId => ev => {
    const { userPropertiesPermissions } = this.state

    if (userPropertiesPermissions.includes(hotelId)) {
      this.setState({
        userPropertiesPermissions: userPropertiesPermissions.filter(id => id !== hotelId)
      })
    } else {
      this.setState({
        userPropertiesPermissions: [...userPropertiesPermissions, hotelId]
      })
    }
  }

  handlePropertiesPermissionUpdate = userId => {
    const { dispatch } = this.props
    const { userPropertiesPermissions } = this.state

    if (userPropertiesPermissions.length === 0) {
      return
    }

    const data = {
      properties_permission: userPropertiesPermissions
    }
    dispatch(
      updateUserPropertiesPermissions(data, userId, (statusCode, body) => {
        if (statusCode !== 200) {
          return alertify.error('Failed to update properties permissions')
        }
        if (window.location.pathname !== `/account/${userId}/admins`) {
          this.handleCancel()
        }
      })
    )
  }

  handleCancel = ev => {
    ev && ev.preventDefault()

    const { match, history } = this.props
    const { selectedAccountId } = match && match.params
    history.push(`/account/${selectedAccountId}/admins`)
  }

  handleReset = ev => {
    ev.preventDefault()

    this.setState({
      user: this.getUserProps(this.props),
      password: '',
      confirmPassword: ''
    })
  }

  handlePasswordChange = ev => {
    ev.preventDefault()
    this.setState({ [ev.target.name]: ev.target.value })
  }

  handlePasswordUpdate = ev => {
    ev.preventDefault()

    const { dispatch, match } = this.props
    const { selectedAccountId, userId } = match && match.params
    const { password, confirmPassword } = this.state

    const params = {
      password: password,
      password_confirmation: confirmPassword
    }

    this.setState({ isSavingPassword: true, errorUpdatingPassword: null })
    dispatch(
      updateAccountAdminPassword(selectedAccountId, userId, params, (statusCode, body) => {
        this.setState({ isSavingPassword: false })
        if (statusCode !== 200) {
          return this.setState({ errorUpdatingPassword: body && body.error })
        }

        this.setState({ password: '', confirmPassword: '' })
        alertify.success('User password successfully updated.')
        this.handleCancel()
      })
    )
  }

  handleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  getHotelName = user => {
    if (!user) return ''
    const hotelId = user.get('hotel_id')
    const hotel = this.props.hotels.find(hotel => hotel.get('id') === hotelId)
    return hotel ? hotel.get('name') : ''
  }

  render() {
    const { hotels, match } = this.props
    const { selectedAccountId, userId } = match && match.params
    const {
      isSaving,
      error,
      userPropertiesPermissions,
      user,
      isSavingPassword,
      errorUpdatingPassword,
      password,
      confirmPassword,
      showPassword
    } = this.state

    if (!user) return <div />

    const orderedHotels =
      hotels &&
      hotels
        .toList()
        .filter(hotel => hotel.get('account_id') === selectedAccountId)
        .sortBy(hotel => hotel.get('name'))

    return (
      <div className="hotel-staff-account-settings">
        <form disabled={isSaving} onSubmit={this.handleSubmit}>
          <h3> User Information </h3>
          <p>
            Belongs to <b>{this.getHotelName(user)}</b>
          </p>
          <div className="column-layout">
            <div className="account-info">
              <div className="name">
                <input
                  disabled={isSaving}
                  type="text"
                  name="first_name"
                  value={user.get('first_name') || ''}
                  placeholder="First name..."
                  onChange={this.handleChange}
                />
                <input
                  disabled={isSaving}
                  type="text"
                  name="last_name"
                  value={user.get('last_name') || ''}
                  placeholder="Last name..."
                  onChange={this.handleChange}
                />

                <span className="title-span-left">(</span>
                <input
                  disabled={isSaving}
                  type="text"
                  name="title"
                  value={user.get('title') || ''}
                  placeholder="Title..."
                  onChange={this.handleChange}
                />
                <span>)</span>
              </div>

              <input
                disabled={userId}
                type="text"
                className="w-60pct"
                name="email"
                value={user.get('email') || ''}
                placeholder="Email address..."
                onChange={this.handleChange}
              />
              <input
                disabled={isSaving}
                className="w-60pct"
                type="text"
                name="phone"
                value={user.get('phone') || ''}
                placeholder="Mobile number..."
                onChange={this.handleChange}
              />
            </div>
          </div>

          {error && (
            <fieldset id="errors">
              {error.entrySeq().map(([key, value]) => (
                <div key={key} className="error">
                  <span>{value}.</span>
                </div>
              ))}
            </fieldset>
          )}

          <div className="user-permissions">
            <h3>Property Permissions</h3>
            <div className="permissions">
              <table>
                <tbody>
                  <tr>
                    <td>Property</td>
                    <td>Can access?</td>
                  </tr>
                  {orderedHotels.map((hotel, i) => (
                    <tr key={i}>
                      <td>{hotel.get('name')}</td>
                      <td>
                        <input
                          type="checkbox"
                          onChange={this.updatePropertiesPermission(hotel.get('id'))}
                          checked={
                            userPropertiesPermissions &&
                            userPropertiesPermissions.includes(hotel.get('id'))
                          }
                          disabled={isSaving}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="actions tmargin1em bmargin1em">
            <button disabled={isSaving} type="submit" className="button">
              {userId ? 'Save changes' : 'Create Account Admin'}
            </button>
            <button
              disabled={isSaving}
              type="cancel"
              className="button"
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </div>
        </form>
        {userId && (
          <div>
            <h2 className="sub-section-heading">Change User Password</h2>
            <small>Password should be minimum 8 characters. </small>
            <form disabled={isSavingPassword}>
              <div className="w-60pct tmargin1em">
                <input
                  disabled={isSavingPassword}
                  type="password"
                  name="password"
                  value={password}
                  placeholder="Password"
                  onChange={this.handlePasswordChange}
                />
                <input
                  disabled={isSavingPassword}
                  type={showPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  value={confirmPassword}
                  placeholder="Confirm password"
                  onChange={this.handlePasswordChange}
                />
                {this.state.confirmPassword ? (
                  <ToggleShowPassword
                    showPassword={showPassword}
                    handleShowPassword={this.handleShowPassword}
                  />
                ) : null}
              </div>
              <div className="actions">
                <button
                  className="button"
                  onClick={this.handlePasswordUpdate}
                  disabled={
                    this.state.password !== this.state.confirmPassword ||
                    !this.state.password ||
                    this.state.password.length < 8 ||
                    isSavingPassword
                  }
                >
                  Update
                </button>
                <button
                  disabled={isSaving}
                  type="cancel"
                  className="button"
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>
              </div>
            </form>

            {errorUpdatingPassword && <div className="error">{errorUpdatingPassword}</div>}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  accounts: state.accounts,
  hotels: state.hotels,
  accountAdmins: state.accountAdmins
})

export default withRouter(connect(mapStateToProps)(EditAccountAdmin))
