import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import alertify from 'alertifyjs'
import _ from 'lodash'

import Loader from './Loader'
import HighlightSearchMatches from './HighlightSearchMatches'
import { loadWrongInHouseGuests, checkoutWrongInHouseGuests } from '../actions/HotelActions'
import HotelUtils from '../utils/HotelUtils'

class WrongInHouseGuests extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedGuests: []
    }
  }

  componentDidMount() {
    this.fetchWrongInHouseGuests()
  }

  fetchWrongInHouseGuests = () => {
    const { dispatch, match } = this.props
    const { selectedHotelId } = match && match.params

    dispatch(
      loadWrongInHouseGuests(selectedHotelId, (statusCode, body) => {
        this.setState({ loadingMoreStaff: false })
        if (statusCode !== 200) {
          return alertify.error((body && body.error) || 'Error loading wrong in house guests.')
        }
      })
    )
  }

  handleSearch = ev => {
    this.setState({ query: ev.target.value })
  }

  handleGuestSelect = guestId => {
    this.setState(prevState => {
      const selectedGuests = new Set(prevState.selectedGuests)
      if (selectedGuests.has(guestId)) {
        selectedGuests.delete(guestId)
      } else {
        selectedGuests.add(guestId)
      }
      return { selectedGuests: Array.from(selectedGuests) }
    })
  }

  handleSelectAllGuests = () => {
    const { wrongInHouseGuests } = this.props
    const allGuestIds = wrongInHouseGuests.toList().map(guest => guest.get('id'))
    const { selectedGuests } = this.state

    if (selectedGuests.length === allGuestIds.length) {
      this.setState({ selectedGuests: [] })
    } else {
      this.setState({ selectedGuests: allGuestIds })
    }
  }

  formatDate = dateString => {
    if (!dateString) return ''
    const options = { year: 'numeric', month: 'short', day: '2-digit' }
    return new Date(dateString).toLocaleDateString('en-US', options)
  }

  handleCheckout = () => {
    const { dispatch, match } = this.props
    const { selectedGuests } = this.state
    const { selectedHotelId } = match && match.params

    dispatch(
      checkoutWrongInHouseGuests(selectedHotelId, selectedGuests, (statusCode, body) => {
        this.setState({ loadingMoreStaff: false })
        if (statusCode !== 200) {
          return alertify.error((body && body.error) || 'Error checking out wrong in house guests.')
        }

        return alertify.success('Guests checked out successfully.')
      })
    )
  }

  render() {
    const { isLoadingWrongInHouseGuests, wrongInHouseGuests } = this.props
    const { query, selectedGuests } = this.state

    const formattedWrongInHouseGuests = wrongInHouseGuests
      .toList()
      .sortBy(guest => _.toLower(HotelUtils.getGuestName(guest)))

    return (
      <div className="settings-container wrong-in-house-guests-settings display-flex-column">
        <Loader isLoading={isLoadingWrongInHouseGuests} />
        <div ref={node => (this.hotelContentWraper = node)}>
          <div className="header-items">
            <h1>
              Wrong In-house Guests{' '}
              <span className="entity-count">({formattedWrongInHouseGuests.size})</span>
            </h1>
          </div>

          <div className="wrong-in-house-guests">
            <table>
              {formattedWrongInHouseGuests.size > 0 && (
                <thead>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedGuests.size === formattedWrongInHouseGuests.size}
                        onChange={this.handleSelectAllGuests}
                      />
                    </td>
                    <td style={{ fontSize: '12px' }}>Select all</td>
                  </tr>
                </thead>
              )}
              <tbody>
                {formattedWrongInHouseGuests.map(guest => (
                  <tr key={guest.get('id')} className="wrong-in-house-guest">
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedGuests.includes(guest.get('id'))}
                        onChange={() => this.handleGuestSelect(guest.get('id'))}
                      />
                    </td>
                    <td style={{ width: '100%' }}>
                      <strong className="name">
                        <HighlightSearchMatches
                          query={query}
                          messageText={`${guest.get('salutation')} ${guest.get(
                            'first_name'
                          )} ${guest.get('last_name')}`}
                        />
                      </strong>{' '}
                      <small className="email">
                        <HighlightSearchMatches
                          query={query}
                          messageText={`Check-in: ${this.formatDate(guest.get('checkin_time'))}`}
                        />
                      </small>
                      <small className="email">
                        <HighlightSearchMatches
                          query={query}
                          messageText={`Expected check-out: ${this.formatDate(
                            guest.get('expected_checkout_time')
                          )}`}
                        />
                      </small>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="checkout-button-container">
            <button
              onClick={this.handleCheckout}
              disabled={selectedGuests.size === 0 || formattedWrongInHouseGuests.size === 0}
              type="submit"
              className="button"
            >
              {this.state.selectedGuests.size > 1 ? 'Check-out guests' : 'Check-out guest'}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  wrongInHouseGuests: state.wrongInHouseGuests,
  isLoadingWrongInHouseGuests: state.loader.get('isLoadingWrongInHouseGuests')
})

export default connect(mapStateToProps)(WrongInHouseGuests)
