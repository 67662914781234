import React from 'react'
import { connect } from 'react-redux'
import Loader from '../components/Loader'
import { loadAllAccountAdmins } from '../actions/AccountActions'
import { Link } from 'react-router-dom'

class AccountAdmins extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    const { dispatch, match } = this.props
    const { selectedAccountId } = match && match.params
    dispatch(
      loadAllAccountAdmins(selectedAccountId, (statusCode, body) => {
        this.setState({ isLoading: false })
      })
    )
  }

  render() {
    const { isLoading } = this.state
    const accountAdmins = this.props.accountAdmins
    const { selectedAccountId } = this.props.match.params
    return (
      <div className="settings-container brand-tags-settings display-flex-column">
        <Loader isLoading={isLoading} />

        <Link to={`/account/${selectedAccountId}/admins/new`} className="button pull-right">
          Add Admin
        </Link>
        <h2 className="bmargin0-5em">Account Admins </h2>

        <div className="account-admins-wrapper">
          <div className="admins">
            <table width="100%">
              <tbody>
                {accountAdmins.toList().map(user => (
                  <tr key={user.get('id')} className="staff-member">
                    <td style={{ width: '50px' }}>
                      <img src={user.get('avatar')} className="avatar" />
                    </td>
                    <td style={{ width: '100%' }}>
                      <Link to={`/account/${selectedAccountId}/admins/${user.get('id')}`}>
                        <strong className="name">
                          {`${user.get('first_name')} ${user.get('last_name')}`}
                        </strong>{' '}
                        <small className="title">{user.get('title')}</small>
                        <small className="email">{user.get('email')}</small>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  accountAdmins: state.accountAdmins
})

export default connect(mapStateToProps)(AccountAdmins)
