/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, July 2018
 */

import { fromJS } from 'immutable'

const HotelPlatformConstants = fromJS([
  {
    uiKey: 'facebook',
    uiName: 'Facebook',
    logoUrl: '/icons/facebook-96.svg',
    apiUrlKey: 'facebook',
    platformData: {
      page_id: undefined,
      access_token: undefined,
      app_token: undefined,
      deep_url: undefined,
      platform_text: undefined,
      has_desktop_version: false,
      order: 1
    }
  },
  {
    uiKey: 'whatsapp_chatapi',
    uiName: 'WhatsApp API',
    logoUrl: '/icons/whatsapp-96.svg',
    apiUrlKey: 'whatsapp_chatapi',
    platformData: {
      phone: undefined,
      access_token: undefined,
      api_endpoint: undefined,
      platforms_server_url: undefined,
      deep_url: undefined,
      platform_text: undefined,
      has_desktop_version: false,
      order: 2
    }
  },
  {
    uiKey: 'whats_app',
    uiName: 'WhatsApp',
    logoUrl: '/icons/whatsapp-96.svg',
    apiUrlKey: 'whats_app',
    platformData: {
      phone: undefined,
      deep_url: undefined,
      platform_text: undefined,
      has_desktop_version: false,
      order: 3
    }
  },
  {
    uiKey: 'message_bird',
    uiName: 'WhatsApp-MessageBird',
    logoUrl: '/icons/whatsapp-business.png',
    apiUrlKey: 'message_bird',
    platformData: {
      phone: undefined,
      deep_url: undefined,
      platform_text: undefined,
      has_desktop_version: false,
      order: 4
    }
  },
  {
    uiKey: 'line',
    uiName: 'Line',
    logoUrl: '/icons/line-96.svg',
    apiUrlKey: 'line',
    platformData: {
      endpoint: undefined,
      channel_access_token: undefined,
      deep_url: undefined,
      platform_text: undefined,
      has_desktop_version: false,
      order: 5
    }
  },
  {
    uiKey: 'we_chat',
    uiName: 'WeChat',
    logoUrl: '/icons/we_chat.svg',
    apiUrlKey: 'we_chat',
    platformData: {
      we_chat_id: undefined,
      app_id: undefined,
      app_secret: undefined,
      deep_url: undefined,
      platform_text: undefined,
      has_desktop_version: false,
      order: 6
    }
  },
  {
    uiKey: 'skype',
    uiName: 'Skype',
    logoUrl: '/icons/skype-96.svg',
    apiUrlKey: 'skype',
    platformData: {
      app_id: undefined,
      app_password: undefined,
      deep_url: undefined,
      platform_text: undefined,
      has_desktop_version: false,
      order: 7
    }
  },
  {
    uiKey: 'tele_gram',
    uiName: 'Telegram',
    logoUrl: '/icons/telegram-96.svg',
    apiUrlKey: 'tele_gram',
    platformData: {
      bot_id: undefined,
      bot_token: undefined,
      web_hook_url: undefined,
      deep_url: undefined,
      platform_text: undefined,
      has_desktop_version: false,
      order: 8
    }
  },
  {
    uiKey: 'any_sms',
    uiName: 'Any-SMS',
    logoUrl: '/icons/sms.svg',
    apiUrlKey: 'any_sms',
    platformData: {
      from: undefined,
      vendor: 'ANYSMS',
      app_id: undefined,
      pass: undefined,
      is_local: true,
      deep_url: undefined,
      platform_text: undefined,
      has_desktop_version: false,
      order: 9
    }
  },
  {
    uiKey: 'nexmo',
    uiName: 'Nexmo',
    logoUrl: '/icons/nexmo.jpg',
    apiUrlKey: 'nexmo',
    platformData: {
      from: undefined,
      vendor: 'NEXMO',
      key: undefined,
      secret: undefined,
      is_local: true,
      deep_url: undefined,
      platform_text: undefined,
      has_desktop_version: false,
      order: 10
    }
  },
  {
    uiKey: 'oxygen8',
    uiName: 'Oxygen8',
    logoUrl: '/icons/oxygen8.png',
    apiUrlKey: 'oxygen8',
    platformData: {
      from: undefined,
      vendor: 'OXYGEN8',
      username: undefined,
      pass: undefined,
      end_url: undefined,
      is_local: true,
      deep_url: undefined,
      platform_text: undefined,
      has_desktop_version: false,
      order: 11
    }
  },
  {
    uiKey: 'send_grid',
    uiName: 'SendGrid',
    logoUrl: '/icons/sendgrid.png',
    apiUrlKey: 'send_grid',
    platformData: {
      vendor: 'SENDGRID',
      api_key: undefined,
      from: undefined,
      order: 13
    }
  },
  {
    uiKey: 'whatcounts',
    uiName: 'WhatCounts',
    logoUrl: '/icons/whatcounts.png',
    apiUrlKey: 'whatcounts',
    platformData: {
      vendor: 'WHATCOUNTS',
      from: undefined,
      template_variable: undefined,
      template_id: undefined,
      realm: undefined,
      password: undefined,
      format: 2,
      order: 14
    }
  },
  {
    uiKey: 'twitter',
    uiName: 'Twitter',
    logoUrl: '/icons/twitter.svg',
    apiUrlKey: 'twitter',
    platformData: {
      page_id: undefined,
      access_token: undefined,
      access_token_secret: undefined,
      consumer_key: undefined,
      consumer_secret: undefined,
      web_hook_url: undefined,
      has_desktop_version: false,
      is_active: true,
      env_name: undefined,
      order: 15
    }
  },
  {
    uiKey: 'custom_platform',
    uiName: 'Custom Platform',
    // logoUrl: '/icons/connect.png',
    logoUrl: '/icons/livechat.png',
    apiUrlKey: 'custom_platform',
    platformData: {
      client_key: undefined,
      client_secret: undefined,
      server_endpoint: undefined,
      server_profile_endpoint: undefined,
      deep_url: undefined,
      platform_text: undefined,
      has_desktop_version: false,
      order: 16
    }
  },
  {
    uiKey: 'viber',
    uiName: 'Viber',
    logoUrl: '/icons/viber.png',
    apiUrlKey: 'viber',
    platformData: {
      access_token: undefined,
      web_hook_url: undefined,
      is_active: true,
      deep_url: undefined,
      platform_text: undefined,
      has_desktop_version: false,
      order: 17
    }
  },
  {
    uiKey: 'zalo',
    uiName: 'Zalo',
    logoUrl: '/icons/zalo.png',
    apiUrlKey: 'zalo',
    platformData: {
      recipient_id: undefined,
      app_id: undefined,
      app_secret: undefined,
      access_token: undefined,
      refresh_token: undefined,
      deep_url: undefined,
      platform_text: undefined,
      has_desktop_version: false,
      order: 18
    }
  },
  {
    uiKey: 'instagram',
    uiName: 'Instagram',
    logoUrl: '/icons/instagram.png',
    apiUrlKey: 'instagram',
    platformData: {
      page_id: undefined,
      access_token: undefined,
      channel_id: undefined,
      deep_url: undefined,
      platform_text: undefined,
      has_desktop_version: false,
      order: 19
    }
  },
  {
    uiKey: 'twilio',
    uiName: 'Twilio',
    logoUrl: '/icons/twilio.png',
    apiUrlKey: 'twilio',
    platformData: {
      from: undefined,
      vendor: 'TWILIO',
      account_sid: undefined,
      auth_token: undefined,
      is_local: true,
      deep_url: undefined,
      platform_text: undefined,
      has_desktop_version: false,
      order: 20
    }
  },
  {
    uiKey: 'whatsapp_twilio',
    uiName: 'WhatsApp-Twilio',
    logoUrl: '/icons/whatsapp-twilio.png',
    apiUrlKey: 'whatsapp_twilio',
    platformData: {
      phone: undefined,
      deep_url: undefined,
      platform_text: undefined,
      has_desktop_version: false,
      account_sid: undefined,
      auth_token: undefined,
      order: 21
    }
  }
])

export default HotelPlatformConstants
