/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2019
 */

const ReplyTemplateCategories = {
  marketing: 'Marketing',
  authentication: 'Authentication',
  utility: 'Utility'
}

export default ReplyTemplateCategories
