import React from 'react'
import _ from 'lodash'

import Loader from './Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { loadAllFeatureFlags, updateAccountFeatureFlag } from '../actions/AccountActions'

class AccountFeatureFlag extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      expandedCategories: []
    }
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    const { selectedAccountId } = match && match.params

    this.setState({ isLoading: true })
    dispatch(
      loadAllFeatureFlags(selectedAccountId, () => {
        this.setState({ isLoading: false })
      })
    )

    this.preloadImage('/icons/select-tick.svg')
  }

  preloadImage = src => {
    const img = new Image()
    img.src = src
  }

  toggleFlag = (flagKey, value) => ev => {
    const { dispatch, match } = this.props
    const { selectedAccountId } = match && match.params
    const newFlagValue = !value

    this.setState({ updatingFlag: flagKey, error: null })
    dispatch(
      updateAccountFeatureFlag(selectedAccountId, flagKey, newFlagValue, (statusCode, body) => {
        this.setState({ updatingFlag: false })
        if (statusCode !== 200) {
          return this.setState({ error: body && body.error })
        }
        loadAllFeatureFlags(selectedAccountId)
      })
    )
  }

  toggleCategory = category => {
    this.setState(prevState => ({
      expandedCategories: prevState.expandedCategories.includes(category)
        ? prevState.expandedCategories.filter(c => c !== category)
        : [...prevState.expandedCategories, category]
    }))
  }

  render() {
    const { isLoading, updatingFlag, error, expandedCategories } = this.state
    const accountFeatureFlags = this.props.accountFeatureFlags

    const sortedFlags = accountFeatureFlags.sortBy(
      (flag, key) => _.toLower(flag.get('display_name')) || key
    )

    const categories = sortedFlags.groupBy(flag => flag.get('category')).toJS()
    categories['Other Features'] = { ...categories['Other Features'], ...categories['null'] }
    delete categories['null']

    return (
      <div className="settings-container brand-tags-settings display-flex-column">
        <Loader isLoading={isLoading} />

        <h1 className="bmargin0-5em">Account Feature Flags </h1>

        <div className="feature-flags-wrapper">
          {Object.keys(categories).map(category => (
            <div key={category}>
              <h4 className="sub-heading" onClick={() => this.toggleCategory(category)}>
                {category}
                <FontAwesomeIcon
                  className={classNames('arrow', {
                    expanded: expandedCategories.includes(category),
                    collapsed: !expandedCategories.includes(category)
                  })}
                  icon={faCaretDown}
                />
              </h4>
              {expandedCategories &&
                expandedCategories.includes(category) &&
                Object.entries(categories[category]).map(([key, flag]) => (
                  <div
                    key={key}
                    className={`flag-item ${updatingFlag === key && 'custom-disabled'}`}
                    onClick={this.toggleFlag(flag.key, flag.enabled)}
                  >
                    <div className={`flag-select ${flag.enabled ? 'selected' : ''}`}>
                      {flag.enabled && <img src={`/icons/select-tick.svg`} />}
                    </div>
                    <div className="flag-name">
                      {flag.display_name || flag.key.replace(/_/g, ' ')}
                    </div>
                    <div className="description" title={flag.description}>
                      {flag.description}
                    </div>
                  </div>
                ))}
            </div>
          ))}
          {error && <div className="error tmargin1em">{error}</div>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  accountFeatureFlags: state.accountFeatureFlags
})

export default connect(mapStateToProps)(AccountFeatureFlag)
