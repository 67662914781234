/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

import ApiUtils from '../utils/ApiUtils'
import ActionTypes from '../constants/ActionTypes'
import axios from 'axios'

const {
  REQUEST_GET_ACCOUNT_HOTELS,
  RECEIVE_GET_ACCOUNT_HOTELS,
  RECEIVE_GET_ACCOUNT_HOTEL,
  RECEIVE_UPDATE_HOTEL_LOGO,
  RECEIVE_UPDATE_LANDING_PAGE_BACKGROUND_IMAGE,
  RECEIVE_DELETE_ACCOUNT_HOTEL,
  RECEIVE_GET_HOTEL_USERS,
  RECEIVE_GET_CREATE_HOTEL_USERS,
  RECEIVE_LOADING_HOTEL_USERS,
  RECEIVE_DELETE_HOTEL_USER,
  RECEIVE_GET_HOTEL_USER,
  RECEIVE_GET_HOTEL_TEAMS,
  RECEIVE_GET_HOTEL_TEAM,
  RECEIVE_DELETE_HOTEL_TEAM,
  RECEIVE_POST_TEAM_HOTEL_USER_ADD,
  RECEIVE_POST_TEAM_HOTEL_USER_REMOVE,
  RECEIVE_GET_HOTEL_FEATURE_FLAGS,
  RECEIVE_GET_HOTEL_FEATURE_FLAG_UPDATE,
  RECEIVE_GET_HOTEL_WHATSAPP_REPLY_TEMPLATES,
  RECEIVE_GET_HOTEL_WHATSAPP_REPLY_TEMPLATE,
  RECEIVE_GET_HOTEL_WECHAT_REPLY_TEMPLATES,
  RECEIVE_GET_HOTEL_WECHAT_REPLY_TEMPLATE,
  RECEIVE_DELETE_HOTEL_WHATSAPP_REPLY_TEMPLATE,
  RECEIVE_DELETE_HOTEL_WECHAT_REPLY_TEMPLATE,
  RECEIVE_GET_HOTEL_LINE_REPLY_TEMPLATES,
  RECEIVE_GET_HOTEL_LINE_REPLY_TEMPLATE,
  RECEIVE_DELETE_HOTEL_LINE_REPLY_TEMPLATE,
  RECEIVE_GET_GUEST_OPT_IN_DETAILS,
  RECEIVE_GET_GUEST_OPT_IN_UPDATE,
  RECEIVE_GET_HOTEL_TIMEZONES,
  RECEIVE_GET_HOTEL_QUICK_REPLY,
  RECEIVE_GET_HOTEL_QUICK_REPLIES,
  RECEIVE_DELETE_HOTEL_QUICK_REPLY,
  RECEIVE_GET_HOTEL_WIDGETS,
  RECEIVE_GET_HOTEL_WIDGET,
  RECEIVE_GET_HOTEL_NPS_DETAILS,
  RECEIVE_GET_TAGS,
  RECEIVE_GET_BRAND_TAGS,
  RECEIVE_GET_BRAND_TAG,
  RECEIVE_BRAND_TAG_DELETE,
  RECEIVE_GET_HOTEL_SUB_PROPERTIES,
  RECEIVE_GET_HOTEL_SUB_PROPERTY,
  RECEIVE_UPDATE_HOTEL_SUB_PROPERTY_LOGO,
  RECEIVE_GET_SALUTATION,
  RECEIVE_GET_SALUTATIONS,
  RECEIVE_GET_HOTEL_SALUTATIONS,
  RECEIVE_GET_WRONG_IN_HOUSE_GUESTS,
  RECEIVE_LOADING_WRONG_IN_HOUSE_GUESTS,
  RECEIVE_CHECKOUT_WRONG_IN_HOUSE_GUESTS
} = ActionTypes

export function saveHotel(accountId, hotel, cb) {
  return dispatch => {
    const params = { hotel }
    const hotelId = hotel.id
    const method = hotelId ? 'put' : 'post'
    const url = hotelId ? `/v1/admin/hotels/${hotelId}` : `/v1/admin/accounts/${accountId}/hotels`

    ApiUtils[method](params, url, (statusCode, body) => {
      if (statusCode === 200 || statusCode === 201) {
        dispatch({
          type: RECEIVE_GET_ACCOUNT_HOTEL,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function loadAccountHotels(accountId, cb) {
  return dispatch => {
    dispatch({
      type: REQUEST_GET_ACCOUNT_HOTELS,
      accountId: accountId,
      data: true
    })

    const url = `/v1/admin/accounts/${accountId}/hotels`
    ApiUtils.get(true, url, (statusCode, body) => {
      dispatch({
        type: REQUEST_GET_ACCOUNT_HOTELS,
        accountId: accountId,
        data: false
      })

      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_ACCOUNT_HOTELS,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function updateHotelLogo(accountId, hotelId, data, cb) {
  return dispatch => {
    const reqProps = {}
    const url = `/v1/admin/hotels/${hotelId}/upload_logo`
    ApiUtils.fileUpload(data, url, reqProps, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_UPDATE_HOTEL_LOGO,
          accountId: accountId,
          hotelId: hotelId,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function updateLandingPageBackgroundImage(accountId, hotelId, data, cb) {
  return dispatch => {
    const reqProps = {}
    const url = `/v1/admin/hotels/${hotelId}/upload_background_image`
    ApiUtils.fileUpload(data, url, reqProps, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_UPDATE_LANDING_PAGE_BACKGROUND_IMAGE,
          accountId: accountId,
          hotelId: hotelId,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function deleteHotel(accountId, hotelId, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/delete`

    ApiUtils.delete(true, url, (statusCode, body) => {
      if (statusCode === 204) {
        dispatch({
          type: RECEIVE_DELETE_ACCOUNT_HOTEL,
          accountId: accountId,
          hotelId: hotelId,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function loadHotelStaff(hotelId, cb) {
  return dispatch => {
    dispatch({
      type: RECEIVE_LOADING_HOTEL_USERS,
      data: true
    })
    const url = `/v1/admin/hotels/${hotelId}/users`
    ApiUtils.get('', url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_USERS,
          hotelId: hotelId,
          data: body
        })
      }

      dispatch({
        type: RECEIVE_LOADING_HOTEL_USERS,
        data: false
      })

      cb && cb(statusCode, body)
    })
  }
}

export function createHotelUsers(hotelId, users, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/users`
    const params = { users }

    ApiUtils.post(params, url, (statusCode, body) => {
      if (statusCode === 200 || statusCode === 201) {
        dispatch({
          type: RECEIVE_GET_CREATE_HOTEL_USERS,
          hotelId: hotelId,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function updateHotelUser(hotelUserId, user, cb) {
  return dispatch => {
    const url = `/v1/admin/users/${hotelUserId}`
    const params = { user }

    ApiUtils.put(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_USER,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function updateHotelUserPassword(hotelId, hotelUserId, user, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/users/${hotelUserId}/change_password`
    const params = { user }

    ApiUtils.post(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_USER,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function updateHotelUserRole(hotelUserId, user, cb) {
  return dispatch => {
    const url = `/v1/admin/users/${hotelUserId}/roles`
    const params = { user }

    ApiUtils.post(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_USER,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function loadHotelTeams(hotelId, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/teams`
    ApiUtils.get(true, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_TEAMS,
          hotelId: hotelId,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function createHotelTeam(hotelId, team, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/teams`
    const params = { team }

    ApiUtils.post(params, url, (statusCode, body) => {
      if (statusCode === 201) {
        dispatch({
          type: RECEIVE_GET_HOTEL_TEAM,
          hotelId: hotelId,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function updateHotelTeam(hotelId, hotelTeamId, team, cb) {
  return dispatch => {
    const url = `/v1/admin/teams/${hotelTeamId}`
    const params = { team }

    ApiUtils.put(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_TEAM,
          hotelId: hotelId,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function deleteHotelTeam(hotelId, hotelTeamId, cb) {
  return dispatch => {
    const url = `/v1/admin/teams/${hotelTeamId}`

    ApiUtils.delete(true, url, (statusCode, body) => {
      if (statusCode === 204) {
        dispatch({
          type: RECEIVE_DELETE_HOTEL_TEAM,
          hotelTeamId: hotelTeamId,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function addHotelUserToTeam(hotelId, hotelTeamId, hotelUserId, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/teams/${hotelTeamId}/user_add`
    const params = {
      team: { user_id: hotelUserId }
    }

    ApiUtils.post(params, url, (statusCode, body) => {
      if (statusCode === 204) {
        dispatch({
          type: RECEIVE_POST_TEAM_HOTEL_USER_ADD,
          hotelTeamId,
          hotelUserId,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function removeHotelUserFromTeam(hotelId, hotelTeamId, hotelUserId, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/teams/${hotelTeamId}/user_remove`
    const params = {
      team: { user_id: hotelUserId }
    }

    ApiUtils.post(params, url, (statusCode, body) => {
      if (statusCode === 204) {
        dispatch({
          type: RECEIVE_POST_TEAM_HOTEL_USER_REMOVE,
          hotelTeamId,
          hotelUserId,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function loadFeatureFlags(hotelId, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/feature_flags`

    ApiUtils.get(true, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_FEATURE_FLAGS,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function updateFeatureFlag(hotelId, flagKey, featureFlags, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/feature_flags`
    const params = {
      feature_flags: featureFlags
    }

    ApiUtils.put(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_FEATURE_FLAG_UPDATE,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function loadReplyTemplates(hotelId, platform, cb) {
  console.log('---platform', platform)

  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/${platform}_engagement_templates`
    const types = {
      whats_app: RECEIVE_GET_HOTEL_WHATSAPP_REPLY_TEMPLATES,
      we_chat: RECEIVE_GET_HOTEL_WECHAT_REPLY_TEMPLATES,
      line: RECEIVE_GET_HOTEL_LINE_REPLY_TEMPLATES
    }

    ApiUtils.get(true, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: types[platform],
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function createReplyTemplate(hotelId, template, platform, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/${platform}_engagement_templates`
    const params =
      platform === 'whats_app'
        ? {
            whats_app_engagement_template: template
          }
        : platform === 'line'
        ? {
            line_engagement_template: template
          }
        : { we_chat_engagement_template: template }

    const types = {
      whats_app: RECEIVE_GET_HOTEL_WHATSAPP_REPLY_TEMPLATE,
      we_chat: RECEIVE_GET_HOTEL_WECHAT_REPLY_TEMPLATE,
      line: RECEIVE_GET_HOTEL_LINE_REPLY_TEMPLATE
    }

    ApiUtils.post(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: types[platform],
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function updateReplyTemplate(templateId, template, platform, cb) {
  return dispatch => {
    const url = `/v1/admin/${platform}_engagement_templates/${templateId}`

    const params =
      platform === 'whats_app'
        ? {
            whats_app_engagement_template: template
          }
        : platform === 'line'
        ? {
            line_engagement_template: template
          }
        : {
            we_chat_engagement_template: template
          }

    ApiUtils.put(params, url, (statusCode, body) => {
      cb && cb(statusCode, body)
    })
  }
}

export function deleteReplyTemplate(templateId, platform, cb) {
  return dispatch => {
    const url = `/v1/admin/${platform}_engagement_templates/${templateId}`
    const types = {
      whats_app: RECEIVE_DELETE_HOTEL_WHATSAPP_REPLY_TEMPLATE,
      we_chat: RECEIVE_DELETE_HOTEL_WECHAT_REPLY_TEMPLATE,
      line: RECEIVE_DELETE_HOTEL_LINE_REPLY_TEMPLATE
    }

    ApiUtils.delete(true, url, (statusCode, body) => {
      if (statusCode === 204) {
        dispatch({
          type: types[platform],
          templateId,
          data: true
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function addReplyTemplateToAllHotels(templateId, platform, cb) {
  return dispatch => {
    const url = `/v1/admin/${platform}_engagement_templates/${templateId}/create_for_all`

    ApiUtils.post(true, url, (statusCode, body) => {
      cb && cb(statusCode, body)
    })
  }
}

export function loadOptInSettings(hotelId, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/guest_opt_in`

    ApiUtils.get(true, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_GUEST_OPT_IN_DETAILS,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function updateOptInSettings(hotelId, guestOptIn, cb) {
  return dispatch => {
    const optInId = guestOptIn.get('id')
    const method = optInId ? 'put' : 'post'

    const url = optInId
      ? `/v1/admin/guest_opt_in/${optInId}`
      : `/v1/admin/hotels/${hotelId}/guest_opt_in`
    const params = {
      guest_opt_in: guestOptIn.toJS()
    }

    ApiUtils[method](params, url, (statusCode, body) => {
      if (statusCode === 200 || statusCode === 201) {
        dispatch({
          type: RECEIVE_GET_GUEST_OPT_IN_UPDATE,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function update2faSettings(hotelId, status, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/2fa/${status ? 'enable' : 'disable'}`

    ApiUtils.post({}, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_ACCOUNT_HOTEL,
          data: { hotel: body }
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function updateDefaultPlatform(hotelId, platform, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/default_platform/${platform}`

    ApiUtils.post({}, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({ type: RECEIVE_GET_ACCOUNT_HOTEL, data: { hotel: body } })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function loadHotelTimezones(cb) {
  return dispatch => {
    const url = `/v1/admin/hotel_timezones`

    ApiUtils.get(true, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_TIMEZONES,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function createQuickReply(hotelId, quickReply, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/quick_replies`
    const params = { quick_replies: quickReply }
    ApiUtils.post(params, url, (statusCode, body) => {
      console.log(statusCode, body)
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_QUICK_REPLY,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function loadQuickReply(hotelId, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/quick_replies`

    ApiUtils.get(true, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_QUICK_REPLIES,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function deleteQuickReply(hotelId, quickReplyId, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/quick_replies/${quickReplyId}`

    ApiUtils.delete(true, url, (statusCode, body) => {
      if (statusCode === 204) {
        dispatch({
          type: RECEIVE_DELETE_HOTEL_QUICK_REPLY,
          quickReplyId,
          data: true
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function updateQuickReply(hotelId, quickReplies, quickReplyId, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/quick_replies/${quickReplyId}`
    const params = { quick_reply: quickReplies[0] }
    ApiUtils.put(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_QUICK_REPLY,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function loadHotelWidgets(hotelId, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/hotel_widgets`

    ApiUtils.get(true, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_WIDGETS,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function createHotelWidget(hotelId, hotelWidget, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/hotel_widgets`
    const params = { hotel_widget: hotelWidget }
    ApiUtils.post(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_WIDGET,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function updateHotelWidget(widgetId, hotelWidget, cb) {
  return dispatch => {
    const url = `/v1/admin/hotel_widgets/${widgetId}`
    const params = { hotel_widget: hotelWidget }
    ApiUtils.put(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_WIDGET,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function loadHotelNpsDetails(hotelId, cb) {
  return dispatch => {
    const url = `/v1/hotels/${hotelId}/nps_details`

    ApiUtils.get(true, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_NPS_DETAILS,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function createHotelNpsDetails(hotelId, npsDetails, cb) {
  return dispatch => {
    const url = `/v1/hotels/${hotelId}/nps_details`
    ApiUtils.post(npsDetails, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_NPS_DETAILS,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function loadHotelTags(hotelId, cb, isBrandTag = false) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/tags`
    ApiUtils.get(true, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: isBrandTag ? RECEIVE_GET_BRAND_TAGS : RECEIVE_GET_TAGS,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function createPropertyTag(accountId, tag, cb) {
  const params = {
    tag: {
      name: tag.get('label'),
      color: tag.get('color'),
      tag_type: 'brand'
    }
  }

  return dispatch => {
    const url = `/v1/admin/hotels/${accountId}/tags`
    ApiUtils.post(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_BRAND_TAG,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function updatePropertyTag(accountId, tag, cb) {
  const params = {
    tag: {
      id: tag.get('id'),
      name: tag.get('name'),
      color: tag.get('color'),
      tag_type: 'brand'
    }
  }

  return dispatch => {
    const url = `/v1/admin/hotels/${accountId}/tags/${tag.get('id')}`
    ApiUtils.put(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_BRAND_TAG,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function deletePropertyTag(accountId, tagId, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${accountId}/tags/${tagId}`

    ApiUtils.delete(true, url, (statusCode, body) => {
      if (statusCode === 204) {
        dispatch({
          type: RECEIVE_BRAND_TAG_DELETE,
          tagId: tagId,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function uploadImageToMessageBird(hotelId, attachmentUnique, data, cb) {
  const url = `/v1/admin/hotels/${hotelId}/attachments/${attachmentUnique}`
  ApiUtils.fileUpload(data, url, null, (statusCode, body) => {
    cb && cb(statusCode, body)
  })
}

export function loadHotelSubProperties(hotelId, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/hotel_properties`

    ApiUtils.get(true, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_SUB_PROPERTIES,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function createHotelSubProperty(hotelId, hotelSubProperty, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/hotel_properties`
    const params = { hotel_property: hotelSubProperty }
    ApiUtils.post(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_SUB_PROPERTY,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function updateHotelSubProperty(subPropertyId, hotelSubProperty, cb) {
  return dispatch => {
    const url = `/v1/admin/hotel_properties/${subPropertyId}`
    const params = { hotel_property: hotelSubProperty }
    ApiUtils.put(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_SUB_PROPERTY,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function updateHotelSubPropertyLogo(accountId, hotelSubPropertyId, data, cb) {
  return dispatch => {
    const reqProps = {}
    const url = `/v1/admin/hotel_properties/${hotelSubPropertyId}/upload_logo`
    ApiUtils.fileUpload(data, url, reqProps, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_UPDATE_HOTEL_SUB_PROPERTY_LOGO,
          accountId: accountId,
          hotelId: hotelSubPropertyId,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function loadSalutations(cb) {
  return dispatch => {
    const url = `/v1/admin/salutations`

    ApiUtils.get(true, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_SALUTATIONS,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function loadHotelSalutations(hotelId, cb) {
  return dispatch => {
    const url = `/v1/hotels/${hotelId}/hotel_salutations`

    ApiUtils.get(true, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_SALUTATIONS,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function createSalutation(data, cb) {
  return dispatch => {
    const url = `/v1/admin/salutations`
    ApiUtils.post(data, url, (statusCode, body) => {
      if (statusCode === 201) {
        dispatch({
          type: RECEIVE_GET_SALUTATION,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function createHotelSalutations(hotelId, salutations, cb) {
  return dispatch => {
    const url = `/v1/hotels/${hotelId}/hotel_salutations`
    ApiUtils.post(salutations, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_SALUTATIONS,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function loadWrongInHouseGuests(hotelId, cb) {
  return dispatch => {
    dispatch({
      type: RECEIVE_LOADING_HOTEL_USERS,
      data: true
    })
    const url = `/v1/admin/hotels/${hotelId}/wrong_in_house_guests`
    ApiUtils.get('', url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_WRONG_IN_HOUSE_GUESTS,
          hotelId: hotelId,
          data: body
        })
      }

      dispatch({
        type: RECEIVE_LOADING_WRONG_IN_HOUSE_GUESTS,
        data: false
      })

      cb && cb(statusCode, body)
    })
  }
}

export function checkoutWrongInHouseGuests(hotelId, selectedGuests, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/wrong_in_house_guests/checkout`
    const params = { checkins: selectedGuests }

    ApiUtils.put(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_CHECKOUT_WRONG_IN_HOUSE_GUESTS,
          hotelId: hotelId,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function deleteHotelUser(userId, cb) {
  return dispatch => {
    const url = `/v1/admin/users/${userId}`
    ApiUtils.delete(true, url, (statusCode, body) => {
      if (statusCode === 204) {
        dispatch({
          type: RECEIVE_DELETE_HOTEL_USER,
          hotelUserId: userId,
          data: body
        })
      }
      cb && cb(statusCode, body)
    })
  }
}
