/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

/* eslint no-mixed-operators: "off" */
/* eslint jsx-a11y/alt-text: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { List } from 'immutable'
import alertify from 'alertifyjs'
import _ from 'lodash'

import Loader from './Loader'
import Spinner from './Spinner'
import HighlightSearchMatches from './HighlightSearchMatches'
import { loadHotelStaff, updateHotelUserRole, deleteHotelUser } from '../actions/HotelActions'
import HotelUtils from '../utils/HotelUtils'
import RegExpUtils from '../utils/RegExpUtils'

class HotelStaff extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      page: 0,
      pageLength: 50
    }
  }

  componentDidMount() {
    this.fetchHotelStaff()
  }

  fetchHotelStaff = () => {
    const { dispatch, match } = this.props
    const { selectedHotelId } = match && match.params

    dispatch(
      loadHotelStaff(selectedHotelId, (statusCode, body) => {
        this.setState({ loadingMoreStaff: false })
        if (statusCode !== 200) {
          return alertify.error((body && body.error) || 'Error loading hotel users.')
        }

        const users = (body && body.users) || []
        this.setState({ moreUsersExists: !_.isEmpty(users) })
      })
    )
  }

  isUserAdmin = user => {
    return !user
      .get('roles')
      .filter(role => role.get('name') === 'hotel_admin')
      .isEmpty()
  }

  getUserName = user => {
    if (!user) return null
    return `${user.get('first_name')} ${user.get('last_name')}`
  }

  updateAdminAccess = user => {
    const { dispatch } = this.props

    const userId = user.get('id')
    const newRole = this.isUserAdmin(user) ? 'staff' : 'hotel_admin'
    const updatedUserJs = user.set('roles', new List([newRole])).toJS()

    dispatch(
      updateHotelUserRole(userId, updatedUserJs, (statusCode, body) => {
        if (statusCode !== 200) {
          return alertify.error((body && body.error) || 'Error updating user role.')
        }

        const message = this.isUserAdmin(user)
          ? `Admin access revoked from ${this.getUserName(user)}.`
          : `Admin access granted to ${this.getUserName(user)}.`
        alertify.success(message)
      })
    )
  }

  handleSearch = ev => {
    this.setState({ query: ev.target.value })
  }

  filterUsers = user => {
    const { query } = this.state
    if (!query) return true

    const exp = new RegExp(RegExpUtils.filter(query), 'i')
    return (
      (`${this.getUserName(user)}` || '').search(exp) !== -1 ||
      (user.get('title') || '').search(exp) !== -1 ||
      (user.get('email') || '').search(exp) !== -1
    )
  }

  handleDelete = user => {
    const userName = `${user.get('first_name')} ${user.get('last_name')}`
    const confirmMsg = `Are you sure you want to delete the user <b>${userName}</b>?`

    alertify.confirm(confirmMsg).set({
      onok: () => {
        this.setState({ isDeleting: true })

        const { dispatch } = this.props
        const userId = user.get('id')

        dispatch(
          deleteHotelUser(userId, (statusCode, body) => {
            this.setState({ isDeleting: false })
            if (statusCode !== 204) {
              const errorMessage =
                (body && body.error && body.error.other) || 'Error deleting user.'
              return alertify.error(errorMessage)
            }
            alertify.success(`User ${this.getUserName(user)} deleted successfully.`)
            this.fetchHotelStaff()
          })
        )
      }
    })
  }

  render() {
    const { match, hotelUsers, isLoadingHotelUsers } = this.props
    const { selectedAccountId, selectedHotelId } = match && match.params
    const { query, loadingMoreStaff } = this.state

    const availableUsers = hotelUsers
      .toList()
      .filter(user => user.get('hotel_id') === selectedHotelId)
      .filter(this.filterUsers)
      .sortBy(user => _.toLower(HotelUtils.getStaffName(user)))

    return (
      <div className="settings-container staff-settings display-flex-column">
        <Loader isLoading={isLoadingHotelUsers} />
        <div ref={node => (this.hotelContentWraper = node)}>
          <div className="header-items">
            <Link
              to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/staff/new`}
              className="button pull-right"
            >
              Add staff
            </Link>

            <h1>
              Staff <span className="entity-count">({availableUsers.size})</span>
            </h1>
          </div>

          <div className="staff-members">
            <div className="top-notes">
              <input
                className="settings-search-input"
                type="search"
                onChange={this.handleSearch}
                value={query || ''}
                placeholder="Search user"
              />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span className="admin-access-title">Grant admin access?</span>
                <span className="staff-actions-title">Actions</span>
              </div>
            </div>
            <table className="w-100pct">
              <tbody>
                {availableUsers.map(user => (
                  <tr key={user.get('id')} className="staff-member">
                    <td>
                      <Link
                        to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/staff/${user.get(
                          'id'
                        )}`}
                      >
                        <img src={user.get('avatar')} className="avatar" />
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/staff/${user.get(
                          'id'
                        )}`}
                      >
                        <strong className="name">
                          <HighlightSearchMatches
                            query={query}
                            messageText={`${user.get('first_name')} ${user.get('last_name')}`}
                          />
                        </strong>{' '}
                        <small className="title">
                          <HighlightSearchMatches query={query} messageText={user.get('title')} />
                        </small>
                        <small className="email">
                          <HighlightSearchMatches query={query} messageText={user.get('email')} />
                        </small>
                      </Link>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={this.isUserAdmin(user)}
                        onChange={() => this.updateAdminAccess(user)}
                      />
                    </td>
                    <td>
                      <button
                        type="button"
                        className="delete-button"
                        onClick={() => this.handleDelete(user)}
                        disabled={this.state.isDeleting}
                      >
                        <img src="/icons/delete.svg" alt="Delete" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="spinner__wrapper">
          {loadingMoreStaff ? (
            <div className="messages__spinner list-style-none">
              <Spinner />
            </div>
          ) : (
            <div className="filler"> </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  hotelUsers: state.hotelUsers,
  isLoadingHotelUsers: state.loader.get('isLoadingHotelUsers')
})

export default connect(mapStateToProps)(HotelStaff)
