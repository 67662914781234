import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const { RECEIVE_GET_ACCOUNT_FEATURE_FLAGS, RECEIVE_GET_ACCOUNT_FEATURE_FLAG_UPDATE } = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data } = action

  switch (type) {
    case RECEIVE_GET_ACCOUNT_FEATURE_FLAGS:
      const accountFeatureFlags = new OrderedMap(
        data.feature_flags.map(setting => [setting.id, fromJS(setting)])
      )
      return state.merge(accountFeatureFlags)

    case RECEIVE_GET_ACCOUNT_FEATURE_FLAG_UPDATE:
      return state.merge(fromJS({ [data.feature_flag.id]: data.feature_flag }))

    default:
      return state
  }
}
