/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, July 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { fromJS, is } from 'immutable'
import alertify from 'alertifyjs'
import _ from 'lodash'

import {
  addPlatform,
  updatePlatform,
  updatePlatformQrCodeImage,
  updatePlatformsOrderInBulk,
  getWebhook
} from '../actions/HotelPlatformActions'

const DeepUrlFormField = ({ platform, handleChange, error }) => {
  return (
    <fieldset className="form-item">
      <legend>Deep URL</legend>
      <input
        type="text"
        name="deep_url"
        onChange={handleChange}
        value={platform.get('deep_url') || ''}
        className={error && error.has('deep_url') ? 'error' : ''}
      />
    </fieldset>
  )
}

const PlatformTextFormField = ({ platform, handleChange, error }) => {
  return (
    <fieldset className="form-item">
      <legend>Platform Text</legend>
      <input
        type="text"
        name="platform_text"
        onChange={handleChange}
        value={platform.get('platform_text') || ''}
        className={error && error.has('platform_text') ? 'error' : ''}
      />
    </fieldset>
  )
}

const HasDesktopVersionFormField = ({ platform, handleBoolFieldChange, error }) => {
  return (
    <fieldset className="form-item">
      <div>
        <div
          className={`select-field ${platform.get('has_desktop_version') ? 'selected' : ''}`}
          onClick={handleBoolFieldChange('has_desktop_version')}
        >
          {platform.get('has_desktop_version') && <img src={`/icons/select-tick.svg`} alt="" />}
        </div>
        <div>Has Desktop Version</div>
      </div>
    </fieldset>
  )
}

const HasEnablePlatform = ({ platform, handleBoolFieldChange, error }) => {
  return (
    <fieldset className="form-item">
      <div>
        <div
          className={`select-field ${platform.get('is_active') ? 'selected' : ''}`}
          onClick={handleBoolFieldChange('is_active')}
        >
          {platform.get('is_active') && <img src={`/icons/select-tick.svg`} alt="" />}
        </div>
        <div>{platform.get('is_active') ? 'Platform Active' : 'Platform Inactive'}</div>
      </div>
    </fieldset>
  )
}

class PlatformQrCodeImage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  handleQrCodeUpload = ev => {
    ev.preventDefault()

    const { dispatch, platform } = this.props

    const platformKey = platform.get('apiUrlKey')
    const platformId = platform.get('id')
    const files = ev.target.files

    let formData = new FormData()
    files && formData.append('qr_code_image', files[0])

    this.setState({ uploading: true, error: null })
    dispatch(
      updatePlatformQrCodeImage(platformKey, platformId, formData, (statusCode, body) => {
        this.setState({ uploading: false })
        if (statusCode !== 200) {
          return this.setState({ error: body && fromJS(body.error) })
        }

        // Unset/Remove the uploaded file from input handler
        this.qrCodeImageInput.value = ''
      })
    )
  }

  render() {
    const { platform } = this.props
    const { uploading, error } = this.state

    return (
      <fieldset>
        <div className="qrcode-wrapper">
          <div className="qrcode-upload">
            <label className="photo photo-input button button-secondary">
              <div className="photo-input__placeholder">
                <strong>
                  <span>{uploading ? 'Uploading ' : 'Upload '}</span>
                  <span>{platform.get('qr_code_image') ? 'New ' : ''}</span>
                  <span>QR Code Image</span>
                </strong>
              </div>
              <input
                type="file"
                id="hotel-logo"
                name="hotel-logo"
                accept=".png,.jpg,.jpeg"
                ref={input => (this.qrCodeImageInput = input)}
                onChange={this.handleQrCodeUpload}
              />
            </label>
            {platform.get('qr_code_image') && (
              <div className="platform-qr-code-preview" title="QR Code Preview">
                <img src={`${platform.get('qr_code_image')}`} alt="" />
              </div>
            )}
          </div>

          {error && (
            <fieldset id="errors">
              {error.entrySeq().map(([key, value]) => (
                <div key={key} className="error">
                  <span className="text-capitalize">{_.replace(key, /_/g, ' ')} </span>
                  <span>{value}.</span>
                </div>
              ))}
            </fieldset>
          )}
        </div>
      </fieldset>
    )
  }
}

class PlatformWebhookStatus extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    this.getWebhook()
  }

  getWebhook = () => {
    const { dispatch, platform } = this.props
    const selectedHotelId = platform.get('hotel_id')
    const webhookUrl = `/v1/admin/hotels/${selectedHotelId}/platforms/webhook`

    const params = {
      platform: platform.get('uiKey'),
      platformId: platform.get('id')
    }

    this.setState({ updatingStatus: true, webhookExists: false })
    dispatch(
      getWebhook(webhookUrl, params, (statusCode, body) => {
        if (statusCode !== 200 && statusCode !== 201) {
          this.setState({ updatingStatus: false })
          return this.setState({ error: body && fromJS(body.error) })
        }

        this.findWebhook(body, selectedHotelId)
        this.setState({ updatingStatus: false })
      })
    )
  }

  findWebhook = (body, selectedHotelId) => {
    const { platform } = this.props
    const channelId = platform.get('channel_id')

    const hotelWebhook =
      body && body.items
        ? body.items.find(item => item.url.includes(selectedHotelId) && item.channelId == channelId)
        : ''

    if (hotelWebhook && Object.keys(hotelWebhook).length !== 0)
      this.setState({ webhookExists: true })
  }

  render() {
    const { webhookExists, updatingStatus } = this.state

    return (
      <fieldset>
        <div className="webhook-wrapper">
          <div className="webhook-status">
            <label
              className={`webhook-input ${webhookExists ? 'approved' : ''} button button-secondary`}
            >
              <strong>
                <span className="webhook-status-button">
                  {updatingStatus
                    ? 'Updating...'
                    : `Webhook status: ${webhookExists ? 'Approved' : 'Pending'}`}
                </span>
              </strong>
              <input
                title="Click to see the latest webhook status"
                id="webhook-status"
                name="webhook-status"
                onClick={this.getWebhook}
              />
            </label>
          </div>
        </div>
      </fieldset>
    )
  }
}

class FormFields extends React.Component {
  handleChange = ev => {
    const value = ev.target.name === 'order' ? parseInt(ev.target.value) : ev.target.value
    this.props.handleFieldChange(ev.target.name, value)
  }

  handleBoolFieldChange = fieldName => ev => {
    const { platform } = this.props
    this.props.handleFieldChange(fieldName, !platform.get(fieldName))
  }

  render() {
    const { dispatch, platform, error, platformsCount } = this.props

    const platformKey = platform.get('uiKey')
    // Facebook Form
    if (platformKey === 'facebook') {
      return (
        <div>
          <fieldset className="form-item">
            <legend>Page Id</legend>
            <input
              type="text"
              name="page_id"
              onChange={this.handleChange}
              value={platform.get('page_id') || ''}
              className={error && error.has('page_id') ? 'error' : ''}
            />
          </fieldset>

          {platform.get('id') && <PlatformQrCodeImage dispatch={dispatch} platform={platform} />}

          <fieldset className="form-item">
            <legend>Access Token</legend>
            <input
              type="text"
              name="access_token"
              onChange={this.handleChange}
              value={platform.get('access_token') || ''}
              className={error && error.has('access_token') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>App Token</legend>
            <input
              type="text"
              name="app_token"
              onChange={this.handleChange}
              value={platform.get('app_token') || ''}
              className={error && error.has('app_token') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Order</legend>
            <input
              type="number"
              name="order"
              onChange={this.handleChange}
              value={platform.get('order') || platformsCount}
              className={error && error.has('order') ? 'error' : ''}
              min="1"
              max={platformsCount.toString()}
            />
          </fieldset>

          <DeepUrlFormField platform={platform} handleChange={this.handleChange} error={error} />

          <PlatformTextFormField
            platform={platform}
            handleChange={this.handleChange}
            error={error}
          />

          <HasDesktopVersionFormField
            platform={platform}
            handleBoolFieldChange={this.handleBoolFieldChange}
            error={error}
          />
        </div>
      )
    } else if (platformKey === 'whatsapp_chatapi') {
      // WhatsApp Chat API Form
      return (
        <div>
          <fieldset className="form-item">
            <legend>Phone Number</legend>
            <input
              type="number"
              name="phone"
              onChange={this.handleChange}
              value={platform.get('phone') || ''}
              className={error && error.has('phone') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Access Token</legend>
            <input
              type="text"
              name="access_token"
              onChange={this.handleChange}
              value={platform.get('access_token') || ''}
              className={error && error.has('access_token') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>API Endpoint</legend>
            <input
              type="text"
              name="api_endpoint"
              onChange={this.handleChange}
              value={platform.get('api_endpoint') || ''}
              className={error && error.has('api_endpoint') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Platforms Server URL</legend>
            <input
              type="text"
              name="platforms_server_url"
              onChange={this.handleChange}
              value={platform.get('platforms_server_url') || ''}
              className={error && error.has('platforms_server_url') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Order</legend>
            <input
              type="number"
              name="order"
              onChange={this.handleChange}
              value={platform.get('order') || platformsCount}
              className={error && error.has('order') ? 'error' : ''}
              min="1"
              max={platformsCount.toString()}
            />
          </fieldset>

          <DeepUrlFormField platform={platform} handleChange={this.handleChange} error={error} />

          <PlatformTextFormField
            platform={platform}
            handleChange={this.handleChange}
            error={error}
          />

          <HasDesktopVersionFormField
            platform={platform}
            handleBoolFieldChange={this.handleBoolFieldChange}
            error={error}
          />
        </div>
      )
    } else if (platformKey === 'message_bird') {
      // WhatsApp Chat API Form
      return (
        <div>
          <fieldset className="form-item">
            <legend>Phone Number</legend>
            <input
              type="number"
              name="phone"
              onChange={this.handleChange}
              value={platform.get('phone') || ''}
              className={error && error.has('phone') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Access Token</legend>
            <input
              type="text"
              name="access_token"
              onChange={this.handleChange}
              value={platform.get('access_token') || ''}
              className={error && error.has('access_token') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Channel ID</legend>
            <input
              type="text"
              name="channel_id"
              onChange={this.handleChange}
              value={platform.get('channel_id') || ''}
              className={error && error.has('channel_id') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Web Hook URL</legend>
            <input
              type="text"
              name="web_hook_url"
              onChange={this.handleChange}
              value={platform.get('web_hook_url') || ''}
              className={error && error.has('web_hook_url') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Order</legend>
            <input
              type="number"
              name="order"
              onChange={this.handleChange}
              value={platform.get('order') || platformsCount}
              className={error && error.has('order') ? 'error' : ''}
              min="1"
              max={platformsCount.toString()}
            />
          </fieldset>

          <DeepUrlFormField platform={platform} handleChange={this.handleChange} error={error} />

          <PlatformTextFormField
            platform={platform}
            handleChange={this.handleChange}
            error={error}
          />

          <HasDesktopVersionFormField
            platform={platform}
            handleBoolFieldChange={this.handleBoolFieldChange}
            error={error}
          />

          {platform.get('id') && <PlatformWebhookStatus dispatch={dispatch} platform={platform} />}
        </div>
      )
    } else if (platformKey === 'whats_app') {
      // WhatsApp Form
      return (
        <div>
          <fieldset className="form-item">
            <legend>Phone Number</legend>
            <input
              type="number"
              name="phone"
              onChange={this.handleChange}
              value={platform.get('phone') || ''}
              className={error && error.has('phone') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Order</legend>
            <input
              type="number"
              name="order"
              onChange={this.handleChange}
              value={platform.get('order') || platformsCount}
              className={error && error.has('order') ? 'error' : ''}
              min="1"
              max={platformsCount.toString()}
            />
          </fieldset>

          <DeepUrlFormField platform={platform} handleChange={this.handleChange} error={error} />

          <PlatformTextFormField
            platform={platform}
            handleChange={this.handleChange}
            error={error}
          />

          <HasDesktopVersionFormField
            platform={platform}
            handleBoolFieldChange={this.handleBoolFieldChange}
            error={error}
          />
        </div>
      )
    } else if (platformKey === 'line') {
      // Line Form
      return (
        <div>
          <fieldset className="form-item">
            <legend>Endpoint</legend>
            <input
              type="text"
              name="endpoint"
              onChange={this.handleChange}
              value={platform.get('endpoint') || ''}
              className={error && error.has('endpoint') ? 'error' : ''}
            />
          </fieldset>

          {platform.get('id') && <PlatformQrCodeImage dispatch={dispatch} platform={platform} />}

          <fieldset className="form-item">
            <legend>Channel Access Token</legend>
            <input
              type="text"
              name="channel_access_token"
              onChange={this.handleChange}
              value={platform.get('channel_access_token') || ''}
              className={error && error.has('channel_access_token') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Order</legend>
            <input
              type="number"
              name="order"
              onChange={this.handleChange}
              value={platform.get('order') || platformsCount}
              className={error && error.has('order') ? 'error' : ''}
              min="1"
              max={platformsCount.toString()}
            />
          </fieldset>

          <DeepUrlFormField platform={platform} handleChange={this.handleChange} error={error} />

          <PlatformTextFormField
            platform={platform}
            handleChange={this.handleChange}
            error={error}
          />

          <HasDesktopVersionFormField
            platform={platform}
            handleBoolFieldChange={this.handleBoolFieldChange}
            error={error}
          />
        </div>
      )
    } else if (platformKey === 'we_chat') {
      // WeChat Form
      return (
        <div>
          <fieldset className="form-item">
            <legend>WeChat Id</legend>
            <input
              type="text"
              name="we_chat_id"
              onChange={this.handleChange}
              value={platform.get('we_chat_id') || ''}
              className={error && error.has('we_chat_id') ? 'error' : ''}
            />
          </fieldset>

          {platform.get('id') && <PlatformQrCodeImage dispatch={dispatch} platform={platform} />}

          <fieldset className="form-item">
            <legend>App Id</legend>
            <input
              type="text"
              name="app_id"
              onChange={this.handleChange}
              value={platform.get('app_id') || ''}
              className={error && error.has('app_id') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>App Secret</legend>
            <input
              type="text"
              name="app_secret"
              onChange={this.handleChange}
              value={platform.get('app_secret') || ''}
              className={error && error.has('app_secret') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Order</legend>
            <input
              type="number"
              name="order"
              onChange={this.handleChange}
              value={platform.get('order') || platformsCount}
              className={error && error.has('order') ? 'error' : ''}
              min="1"
              max={platformsCount.toString()}
            />
          </fieldset>

          <DeepUrlFormField platform={platform} handleChange={this.handleChange} error={error} />

          <PlatformTextFormField
            platform={platform}
            handleChange={this.handleChange}
            error={error}
          />

          <HasDesktopVersionFormField
            platform={platform}
            handleBoolFieldChange={this.handleBoolFieldChange}
            error={error}
          />
        </div>
      )
    } else if (platformKey === 'skype') {
      // Skype Form
      return (
        <div>
          <fieldset className="form-item">
            <legend>App Id</legend>
            <input
              type="text"
              name="app_id"
              onChange={this.handleChange}
              value={platform.get('app_id') || ''}
              className={error && error.has('app_id') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>App Password</legend>
            <input
              type="text"
              name="app_password"
              onChange={this.handleChange}
              value={platform.get('app_password') || ''}
              className={error && error.has('app_password') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Order</legend>
            <input
              type="number"
              name="order"
              onChange={this.handleChange}
              value={platform.get('order') || platformsCount}
              className={error && error.has('order') ? 'error' : ''}
              min="1"
              max={platformsCount.toString()}
            />
          </fieldset>

          <DeepUrlFormField platform={platform} handleChange={this.handleChange} error={error} />

          <PlatformTextFormField
            platform={platform}
            handleChange={this.handleChange}
            error={error}
          />

          <HasDesktopVersionFormField
            platform={platform}
            handleBoolFieldChange={this.handleBoolFieldChange}
            error={error}
          />
        </div>
      )
    } else if (platformKey === 'tele_gram') {
      // Telegram Form
      return (
        <div>
          <fieldset className="form-item">
            <legend>Bot Id</legend>
            <input
              type="text"
              name="bot_id"
              onChange={this.handleChange}
              value={platform.get('bot_id') || ''}
              className={error && error.has('bot_id') ? 'error' : ''}
            />
          </fieldset>

          {platform.get('id') && <PlatformQrCodeImage dispatch={dispatch} platform={platform} />}

          <fieldset className="form-item">
            <legend>Bot Token</legend>
            <input
              type="text"
              name="bot_token"
              onChange={this.handleChange}
              value={platform.get('bot_token') || ''}
              className={error && error.has('bot_token') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Web Hook URL</legend>
            <input
              type="text"
              name="web_hook_url"
              onChange={this.handleChange}
              value={platform.get('web_hook_url') || ''}
              className={error && error.has('web_hook_url') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Order</legend>
            <input
              type="number"
              name="order"
              onChange={this.handleChange}
              value={platform.get('order') || platformsCount}
              className={error && error.has('order') ? 'error' : ''}
              min="1"
              max={platformsCount.toString()}
            />
          </fieldset>

          <DeepUrlFormField platform={platform} handleChange={this.handleChange} error={error} />

          <PlatformTextFormField
            platform={platform}
            handleChange={this.handleChange}
            error={error}
          />

          <HasDesktopVersionFormField
            platform={platform}
            handleBoolFieldChange={this.handleBoolFieldChange}
            error={error}
          />
        </div>
      )
    } else if (platformKey === 'any_sms') {
      // SMS Form
      return (
        <div>
          <fieldset className="form-item">
            <legend>Vendor</legend>
            <input
              type="text"
              name="vendor"
              onChange={this.handleChange}
              value={platform.get('vendor') || ''}
              className={error && error.has('vendor') ? 'error' : ''}
              disabled
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>From</legend>
            <input
              type="text"
              name="from"
              onChange={this.handleChange}
              value={platform.get('from') || ''}
              className={error && error.has('from') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>App Id</legend>
            <input
              type="text"
              name="app_id"
              onChange={this.handleChange}
              value={platform.get('app_id') || ''}
              className={error && error.has('app_id') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Password</legend>
            <input
              type="text"
              name="pass"
              onChange={this.handleChange}
              value={platform.get('pass') || ''}
              className={error && error.has('pass') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <div>
              <div
                className={`select-field ${platform.get('is_local') ? 'selected' : ''}`}
                onClick={this.handleBoolFieldChange('is_local')}
              >
                {platform.get('is_local') && <img src={`/icons/select-tick.svg`} alt="" />}
              </div>
              <div>Is Local</div>
            </div>
          </fieldset>

          <fieldset className="form-item">
            <legend>Order</legend>
            <input
              type="number"
              name="order"
              onChange={this.handleChange}
              value={platform.get('order') || platformsCount}
              className={error && error.has('order') ? 'error' : ''}
              min="1"
              max={platformsCount.toString()}
            />
          </fieldset>

          <DeepUrlFormField platform={platform} handleChange={this.handleChange} error={error} />

          <PlatformTextFormField
            platform={platform}
            handleBoolFieldChange={this.handleBoolFieldChange}
            error={error}
          />

          <HasDesktopVersionFormField
            platform={platform}
            handleBoolFieldChange={this.handleBoolFieldChange}
            error={error}
          />
        </div>
      )
    } else if (platformKey === 'nexmo') {
      // Nexmo Form
      return (
        <div>
          <fieldset className="form-item">
            <legend>Vendor</legend>
            <input
              type="text"
              name="vendor"
              onChange={this.handleChange}
              value={platform.get('vendor') || ''}
              className={error && error.has('vendor') ? 'error' : ''}
              disabled
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>From</legend>
            <input
              type="text"
              name="from"
              onChange={this.handleChange}
              value={platform.get('from') || ''}
              className={error && error.has('from') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Key</legend>
            <input
              type="text"
              name="key"
              onChange={this.handleChange}
              value={platform.get('key') || ''}
              className={error && error.has('key') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Secret</legend>
            <input
              type="text"
              name="secret"
              onChange={this.handleChange}
              value={platform.get('secret') || ''}
              className={error && error.has('secret') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <div>
              <div
                className={`select-field ${platform.get('is_local') ? 'selected' : ''}`}
                onClick={this.handleBoolFieldChange('is_local')}
              >
                {platform.get('is_local') && <img src={`/icons/select-tick.svg`} alt="" />}
              </div>
              <div>Is Local</div>
            </div>
          </fieldset>

          <fieldset className="form-item">
            <legend>Order</legend>
            <input
              type="number"
              name="order"
              onChange={this.handleChange}
              value={platform.get('order') || platformsCount}
              className={error && error.has('order') ? 'error' : ''}
              min="1"
              max={platformsCount.toString()}
            />
          </fieldset>

          <DeepUrlFormField platform={platform} handleChange={this.handleChange} error={error} />

          <PlatformTextFormField
            platform={platform}
            handleChange={this.handleChange}
            error={error}
          />

          <HasDesktopVersionFormField
            platform={platform}
            handleBoolFieldChange={this.handleBoolFieldChange}
            error={error}
          />
        </div>
      )
    } else if (platformKey === 'oxygen8') {
      // Oxygen8 Form
      return (
        <div>
          <fieldset className="form-item">
            <legend>Vendor</legend>
            <input
              type="text"
              name="vendor"
              onChange={this.handleChange}
              value={platform.get('vendor') || ''}
              className={error && error.has('vendor') ? 'error' : ''}
              disabled
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>From</legend>
            <input
              type="text"
              name="from"
              onChange={this.handleChange}
              value={platform.get('from') || ''}
              className={error && error.has('from') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Username</legend>
            <input
              type="text"
              name="username"
              onChange={this.handleChange}
              value={platform.get('username') || ''}
              className={error && error.has('username') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Password</legend>
            <input
              type="text"
              name="pass"
              onChange={this.handleChange}
              value={platform.get('pass') || ''}
              className={error && error.has('pass') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>End Url</legend>
            <input
              type="text"
              name="end_url"
              onChange={this.handleChange}
              value={platform.get('end_url') || ''}
              className={error && error.has('end_url') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <div>
              <div
                className={`select-field ${platform.get('is_local') ? 'selected' : ''}`}
                onClick={this.handleBoolFieldChange('is_local')}
              >
                {platform.get('is_local') && <img src={`/icons/select-tick.svg`} alt="" />}
              </div>
              <div>Is Local</div>
            </div>
          </fieldset>

          <fieldset className="form-item">
            <legend>Order</legend>
            <input
              type="number"
              name="order"
              onChange={this.handleChange}
              value={platform.get('order') || platformsCount}
              className={error && error.has('order') ? 'error' : ''}
              min="1"
              max={platformsCount.toString()}
            />
          </fieldset>

          <DeepUrlFormField platform={platform} handleChange={this.handleChange} error={error} />

          <PlatformTextFormField
            platform={platform}
            handleChange={this.handleChange}
            error={error}
          />

          <HasDesktopVersionFormField
            platform={platform}
            handleBoolFieldChange={this.handleBoolFieldChange}
            error={error}
          />
        </div>
      )
    } else if (platformKey === 'send_grid') {
      // SendGrid Form
      return (
        <div>
          <fieldset className="form-item">
            <legend>Vendor</legend>
            <input
              type="text"
              name="vendor"
              onChange={this.handleChange}
              value={platform.get('vendor') || ''}
              className={error && error.has('vendor') ? 'error' : ''}
              disabled
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>From</legend>
            <input
              type="text"
              name="from"
              onChange={this.handleChange}
              value={platform.get('from') || ''}
              className={error && error.has('from') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>API Key</legend>
            <input
              type="text"
              name="api_key"
              onChange={this.handleChange}
              value={platform.get('api_key') || ''}
              className={error && error.has('api_key') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Order</legend>
            <input
              type="number"
              name="order"
              onChange={this.handleChange}
              value={platform.get('order') || platformsCount}
              className={error && error.has('order') ? 'error' : ''}
              min="1"
              max={platformsCount.toString()}
            />
          </fieldset>
        </div>
      )
    } else if (platformKey === 'whatcounts') {
      // WhatCounts Form
      return (
        <div>
          <fieldset className="form-item">
            <legend>Vendor</legend>
            <input
              type="text"
              name="vendor"
              onChange={this.handleChange}
              value={platform.get('vendor') || ''}
              className={error && error.has('vendor') ? 'error' : ''}
              disabled
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>From</legend>
            <input
              type="text"
              name="from"
              onChange={this.handleChange}
              value={platform.get('from') || ''}
              className={error && error.has('from') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Template Id</legend>
            <input
              type="text"
              name="template_id"
              onChange={this.handleChange}
              value={platform.get('template_id') || ''}
              className={error && error.has('template_id') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Template Variable</legend>
            <input
              type="text"
              name="template_variable"
              onChange={this.handleChange}
              value={platform.get('template_variable') || ''}
              className={error && error.has('template_variable') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Realm</legend>
            <input
              type="text"
              name="realm"
              onChange={this.handleChange}
              value={platform.get('realm') || ''}
              className={error && error.has('realm') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Password</legend>
            <input
              type="text"
              name="password"
              onChange={this.handleChange}
              value={platform.get('password') || ''}
              className={error && error.has('password') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Format</legend>
            <input
              type="text"
              name="format"
              onChange={this.handleChange}
              value={platform.get('format') || ''}
              className={error && error.has('format') ? 'error' : ''}
              disabled
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Order</legend>
            <input
              type="number"
              name="order"
              onChange={this.handleChange}
              value={platform.get('order') || platformsCount}
              className={error && error.has('order') ? 'error' : ''}
              min="1"
              max={platformsCount.toString()}
            />
          </fieldset>
        </div>
      )
    } else if (platformKey === 'custom_platform') {
      // Custom Platform Form
      return (
        <div>
          <fieldset className="form-item">
            <legend>Client Key</legend>
            <input
              type="text"
              name="client_key"
              onChange={this.handleChange}
              value={platform.get('client_key') || ''}
              className={error && error.has('client_key') ? 'error' : ''}
            />
          </fieldset>

          {platform.get('id') && <PlatformQrCodeImage dispatch={dispatch} platform={platform} />}

          <fieldset className="form-item">
            <legend>Client Secret</legend>
            <input
              type="text"
              name="client_secret"
              onChange={this.handleChange}
              value={platform.get('client_secret') || ''}
              className={error && error.has('client_secret') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Server Endpoint</legend>
            <input
              type="text"
              name="server_endpoint"
              onChange={this.handleChange}
              value={platform.get('server_endpoint') || ''}
              className={error && error.has('server_endpoint') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Server Profile Endpoint</legend>
            <input
              type="text"
              name="server_profile_endpoint"
              onChange={this.handleChange}
              value={platform.get('server_profile_endpoint') || ''}
              className={error && error.has('server_profile_endpoint') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Order</legend>
            <input
              type="number"
              name="order"
              onChange={this.handleChange}
              value={platform.get('order') || platformsCount}
              className={error && error.has('order') ? 'error' : ''}
              min="1"
              max={platformsCount.toString()}
            />
          </fieldset>

          <DeepUrlFormField platform={platform} handleChange={this.handleChange} error={error} />

          <PlatformTextFormField
            platform={platform}
            handleChange={this.handleChange}
            error={error}
          />

          <HasDesktopVersionFormField
            platform={platform}
            handleBoolFieldChange={this.handleBoolFieldChange}
            error={error}
          />
        </div>
      )
    } else if (platformKey === 'twitter') {
      // Twitter Form
      return (
        <div>
          <fieldset className="form-item">
            <legend>Page Id</legend>
            <input
              type="text"
              name="page_id"
              onChange={this.handleChange}
              value={platform.get('page_id') || ''}
              className={error && error.has('page_id') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Access Token</legend>
            <input
              type="text"
              name="access_token"
              onChange={this.handleChange}
              value={platform.get('access_token') || ''}
              className={error && error.has('access_token') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Access Token Secret</legend>
            <input
              type="text"
              name="access_token_secret"
              onChange={this.handleChange}
              value={platform.get('access_token_secret') || ''}
              className={error && error.has('access_token_secret') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Consumer Key</legend>
            <input
              type="text"
              name="consumer_key"
              onChange={this.handleChange}
              value={platform.get('consumer_key') || ''}
              className={error && error.has('consumer_key') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Consumer Secret</legend>
            <input
              type="text"
              name="consumer_secret"
              onChange={this.handleChange}
              value={platform.get('consumer_secret') || ''}
              className={error && error.has('consumer_secret') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Environment Name</legend>
            <input
              type="text"
              name="env_name"
              onChange={this.handleChange}
              value={platform.get('env_name') || ''}
              className={error && error.has('env_name') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Web Hook URL</legend>
            <input
              type="text"
              name="web_hook_url"
              onChange={this.handleChange}
              value={platform.get('web_hook_url') || ''}
              className={error && error.has('web_hook_url') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Order</legend>
            <input
              type="number"
              name="order"
              onChange={this.handleChange}
              value={platform.get('order') || platformsCount}
              className={error && error.has('order') ? 'error' : ''}
              min="1"
              max={platformsCount.toString()}
            />
          </fieldset>
          <HasEnablePlatform
            platform={platform}
            handleBoolFieldChange={this.handleBoolFieldChange}
            error={error}
          />

          <HasDesktopVersionFormField
            platform={platform}
            handleBoolFieldChange={this.handleBoolFieldChange}
            error={error}
          />
        </div>
      )
    } else if (platformKey === 'viber') {
      // Viber Form

      return (
        <div>
          <fieldset className="form-item">
            <legend>Access Token</legend>
            <input
              type="text"
              name="access_token"
              onChange={this.handleChange}
              value={platform.get('access_token') || ''}
              className={error && error.has('access_token') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Web Hook URL</legend>
            <input
              type="text"
              name="web_hook_url"
              onChange={this.handleChange}
              value={platform.get('web_hook_url') || ''}
              className={error && error.has('web_hook_url') ? 'error' : ''}
            />
          </fieldset>

          <DeepUrlFormField platform={platform} handleChange={this.handleChange} error={error} />

          <fieldset className="form-item">
            <legend>Order</legend>
            <input
              type="number"
              name="order"
              onChange={this.handleChange}
              value={platform.get('order') || platformsCount}
              className={error && error.has('order') ? 'error' : ''}
              min="1"
              max={platformsCount.toString()}
            />
          </fieldset>

          <PlatformTextFormField
            platform={platform}
            handleChange={this.handleChange}
            error={error}
          />

          <HasDesktopVersionFormField
            platform={platform}
            handleBoolFieldChange={this.handleBoolFieldChange}
            error={error}
          />
        </div>
      )
    } else if (platformKey === 'zalo') {
      // Zalo Form
      return (
        <div>
          <fieldset className="form-item">
            <legend>Recipient Id</legend>
            <input
              type="text"
              name="recipient_id"
              onChange={this.handleChange}
              value={platform.get('recipient_id') || ''}
              className={error && error.has('recipient_id') ? 'error' : ''}
            />
          </fieldset>

          {platform.get('id') && <PlatformQrCodeImage dispatch={dispatch} platform={platform} />}

          <fieldset className="form-item">
            <legend>App Id</legend>
            <input
              type="text"
              name="app_id"
              onChange={this.handleChange}
              value={platform.get('app_id') || ''}
              className={error && error.has('app_id') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>App Secret</legend>
            <input
              type="text"
              name="app_secret"
              onChange={this.handleChange}
              value={platform.get('app_secret') || ''}
              className={error && error.has('app_secret') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Access Token</legend>
            <input
              type="text"
              name="access_token"
              onChange={this.handleChange}
              value={platform.get('access_token') || ''}
              className={error && error.has('access_token') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Refresh Token</legend>
            <input
              type="text"
              name="refresh_token"
              onChange={this.handleChange}
              value={platform.get('refresh_token') || ''}
              className={error && error.has('refresh_token') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Order</legend>
            <input
              type="number"
              name="order"
              onChange={this.handleChange}
              value={platform.get('order') || platformsCount}
              className={error && error.has('order') ? 'error' : ''}
              min="1"
              max={platformsCount.toString()}
            />
          </fieldset>

          <DeepUrlFormField platform={platform} handleChange={this.handleChange} error={error} />

          <PlatformTextFormField
            platform={platform}
            handleChange={this.handleChange}
            error={error}
          />

          <HasDesktopVersionFormField
            platform={platform}
            handleBoolFieldChange={this.handleBoolFieldChange}
            error={error}
          />
        </div>
      )
    } else if (platformKey === 'instagram') {
      // Instagram Form
      return (
        <div>
          <fieldset className="form-item">
            <legend>Page Id</legend>
            <input
              type="text"
              name="page_id"
              onChange={this.handleChange}
              value={platform.get('page_id') || ''}
              className={error && error.has('page_id') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Access Token</legend>
            <input
              type="text"
              name="access_token"
              onChange={this.handleChange}
              value={platform.get('access_token') || ''}
              className={error && error.has('access_token') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Channel ID</legend>
            <input
              type="text"
              name="channel_id"
              onChange={this.handleChange}
              value={platform.get('channel_id') || ''}
              className={error && error.has('channel_id') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Order</legend>
            <input
              type="number"
              name="order"
              onChange={this.handleChange}
              value={platform.get('order') || platformsCount}
              className={error && error.has('order') ? 'error' : ''}
              min="1"
              max={platformsCount.toString()}
            />
          </fieldset>

          <DeepUrlFormField platform={platform} handleChange={this.handleChange} error={error} />

          <PlatformTextFormField
            platform={platform}
            handleChange={this.handleChange}
            error={error}
          />

          <HasDesktopVersionFormField
            platform={platform}
            handleBoolFieldChange={this.handleBoolFieldChange}
            error={error}
          />

          {platform.get('id') && <PlatformWebhookStatus dispatch={dispatch} platform={platform} />}
        </div>
      )
    } else if (platformKey === 'whatsapp_twilio') {
      // WhatsApp Chat Twilio API Form
      return (
        <div>
          <fieldset className="form-item">
            <legend>Phone Number</legend>
            <input
              type="number"
              name="phone"
              onChange={this.handleChange}
              value={platform.get('phone') || ''}
              className={error && error.has('phone') ? 'error' : ''}/>
          </fieldset>

          <fieldset className="form-item">
            <legend>Account SID</legend>
            <input
              type="text"
              name="account_sid"
              onChange={this.handleChange}
              value={platform.get('account_sid') || ''}
              className={error && error.has('account_sid') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Auth Token</legend>
            <input
              type="text"
              name="auth_token"
              onChange={this.handleChange}
              value={platform.get('auth_token') || ''}
              className={error && error.has('auth_token') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Order</legend>
            <input
              type="number"
              name="order"
              onChange={this.handleChange}
              value={platform.get('order') || platformsCount}
              className={error && error.has('order') ? 'error' : ''}
              min="1"
              max={platformsCount.toString()}
            />
          </fieldset>

          <DeepUrlFormField platform={platform} handleChange={this.handleChange} error={error} />

          <PlatformTextFormField
            platform={platform}
            handleChange={this.handleChange}
            error={error}
          />

          <HasDesktopVersionFormField
            platform={platform}
            handleBoolFieldChange={this.handleBoolFieldChange}
            error={error}
          />
        </div>
      )
    } else if (platformKey === 'twilio') {
      // Twilio Form
      return (
        <div>
          <fieldset className="form-item">
            <legend>Vendor</legend>
            <input
              type="text"
              name="vendor"
              onChange={this.handleChange}
              value={platform.get('vendor') || ''}
              className={error && error.has('vendor') ? 'error' : ''}
              disabled
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>From</legend>
            <input
              type="text"
              name="from"
              onChange={this.handleChange}
              value={platform.get('from') || ''}
              className={error && error.has('from') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Account SID</legend>
            <input
              type="text"
              name="account_sid"
              onChange={this.handleChange}
              value={platform.get('account_sid') || ''}
              className={error && error.has('account_sid') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Auth Token</legend>
            <input
              type="text"
              name="auth_token"
              onChange={this.handleChange}
              value={platform.get('auth_token') || ''}
              className={error && error.has('auth_token') ? 'error' : ''}
            />
          </fieldset>

          <fieldset className="form-item">
            <legend>Order</legend>
            <input
              type="number"
              name="order"
              onChange={this.handleChange}
              value={platform.get('order') || platformsCount}
              className={error && error.has('order') ? 'error' : ''}
              min="1"
              max={platformsCount.toString()}
            />
          </fieldset>

          <DeepUrlFormField platform={platform} handleChange={this.handleChange} error={error} />

          <PlatformTextFormField
            platform={platform}
            handleChange={this.handleChange}
            error={error}
          />

          <HasDesktopVersionFormField
            platform={platform}
            handleBoolFieldChange={this.handleBoolFieldChange}
            error={error}
          />
        </div>
      )
    } else {
      return null
    }
  }
}

class HotelPlatform extends React.Component {
  static propTypes = {
    platform: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      platform: props.platform,
      hotelPlatforms: props.hotelPlatforms
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!is(this.props.platform, nextProps.platform)) {
      this.setState({ platform: nextProps.platform })
    }
  }

  handleFieldChange = (fieldName, fieldValue) => {
    const { platform } = this.state
    this.setState({
      platform: platform.set(fieldName, fieldValue || (fieldValue === '' && null)) // Empty string condition is added for isPristine (form validation) to work
    })
  }

  handleApiResponse = (statusCode, body) => {
    const { selectedAccountId, selectedHotelId, history } = this.props
    const { platform } = this.state

    const platformId = platform.get('id')
    const redirectUrl = `/account/${selectedAccountId}/hotel/${selectedHotelId}/platforms`
    this.setState({ saving: false, updatingActiveStatus: false })
    // Creation and Updation
    if (statusCode !== 200 && statusCode !== 201) {
      if (
        body &&
        body.error &&
        body.error.includes('duplicate key value violates unique constraint')
      ) {
        return alertify.error('This phone number is already in use. Please use a different number.')
      }
      return alertify.error(`Error adding platform: ${body && fromJS(body.error)} `)
    }

    alertify.success(`Platform successfuly ${platformId ? 'updated' : 'added'}.`)
    setTimeout(() => {
      history.push(redirectUrl)
    }, 750)
  }

  submitForm = (ev, action) => {
    ev.preventDefault()
    const { dispatch, selectedHotelId, hotelPlatforms } = this.props
    const { platform } = this.state

    const platformId = platform.get('id')
    const urlKey = platform.get('apiUrlKey')
    const params = {
      [urlKey]:
        action === 'toggle-active-status'
          ? platform.set('is_active', !platform.get('is_active')).toJS()
          : platform.toJS()
    }
    const previousPlatform = hotelPlatforms.get(platformId)
    const previousHotelPlatforms = hotelPlatforms
    this.setState({ error: null })
    if (platform.get('uiName') === 'Twitter') {
      params.twitter.hotel_id = selectedHotelId
    }
    if (action === 'toggle-active-status') {
      this.setState({ updatingActiveStatus: true })
    } else {
      this.setState({ saving: true })
    }
    if (platformId) {
      const updateUrl = `/v1/admin/platforms/${urlKey}/${platformId}`
      dispatch(
        updatePlatform(updateUrl, params, urlKey, (statusCode, body) => {
          this.updatePlatformsOrder(previousPlatform, previousHotelPlatforms)
          this.handleApiResponse(statusCode, body)
        })
      )
    } else {
      const createUrl = `/v1/admin/hotels/${selectedHotelId}/platforms/${urlKey}`
      dispatch(
        addPlatform(createUrl, params, (statusCode, body) => {
          this.updatePlatformsOrder(previousPlatform, previousHotelPlatforms)
          this.handleApiResponse(statusCode, body)
        })
      )
    }
  }

  updatePlatformsOrder = (previousPlatform, previousHotelPlatforms) => {
    const { dispatch, selectedHotelId } = this.props
    const { platform } = this.state
    const platformsToUpdate = []
    const url = `/v1/admin/hotels/${selectedHotelId}/platforms`
    previousHotelPlatforms.filter(obj => {
      if (
        previousPlatform &&
        parseInt(platform.get('order')) < parseInt(previousPlatform.get('order')) &&
        parseInt(obj.get('order')) < parseInt(previousPlatform.get('order')) &&
        parseInt(obj.get('order')) >= parseInt(platform.get('order'))
      ) {
        platformsToUpdate.push({
          id: obj.get('id'),
          apiUrlKey: obj.get('apiUrlKey'),
          order: parseInt(obj.get('order')) + 1
        })
      }
      if (
        previousPlatform &&
        parseInt(platform.get('order')) > parseInt(previousPlatform.get('order')) &&
        parseInt(obj.get('order')) > parseInt(previousPlatform.get('order')) &&
        parseInt(obj.get('order')) <= parseInt(platform.get('order'))
      ) {
        platformsToUpdate.push({
          id: obj.get('id'),
          apiUrlKey: obj.get('apiUrlKey'),
          order: parseInt(obj.get('order')) - 1
        })
      }
      if (!previousPlatform && parseInt(obj.get('order')) >= parseInt(platform.get('order'))) {
        platformsToUpdate.push({
          id: obj.get('id'),
          apiUrlKey: obj.get('apiUrlKey'),
          order: parseInt(obj.get('order')) + 1
        })
      }
    })
    dispatch(
      updatePlatformsOrderInBulk(url, platformsToUpdate, (statusCode, body) => {
        this.handleApiResponse(statusCode, body)
      })
    )
  }

  handleDisable = ev => {
    this.submitForm(ev, 'toggle-active-status')
  }

  handleCancel = ev => {
    ev.preventDefault()

    const { selectedAccountId, selectedHotelId, history } = this.props
    history.push(`/account/${selectedAccountId}/hotel/${selectedHotelId}/platforms`)
  }

  handleReset = ev => {
    ev.preventDefault()
    const { platform } = this.props

    this.setState({
      platform: platform,
      error: null
    })
  }

  isPristine = () => {
    const { platform, saving } = this.state
    const platformProps = this.props.platform

    // Make platforms fields optional while updating platform configuration
    if (platform.get('id')) {
      return is(platform, this.props.platform)
    }

    const editedFields = platform
      .get('platformData')
      .map((value, property) => platform.get(property) !== platformProps.get(property))
      .filter((value, property) => value)

    const emptyFields = platform
      .get('platformData')
      .filter((value, property) => _.includes([null, undefined, ''], platform.get(property)))

    return (
      is(platform, this.props.platform) ||
      editedFields.size === 0 ||
      emptyFields.size !== 0 ||
      saving
    )
  }

  render() {
    const { dispatch, hotelPlatforms, platformsCount } = this.props
    const { platform, saving, updatingActiveStatus, error } = this.state
    return (
      <div className="platform-form">
        <div className={`platform-icon ${platform.get('uiKey')}`}>
          <img src={platform.get('logoUrl')} alt="" />
        </div>

        <form className="form-wrapper" autoComplete="off" onSubmit={this.submitForm}>
          <h2 className="form-heading">
            <span>{platform.get('uiName')} </span>
            {platform.get('id') && !platform.get('is_active') && (
              <span className="opacity5">[Inactive]</span>
            )}
          </h2>

          <FormFields
            dispatch={dispatch}
            platform={platform}
            error={error}
            handleFieldChange={this.handleFieldChange}
            hotelPlatforms={hotelPlatforms}
            platformsCount={platformsCount}
          />

          {error &&
            error.entrySeq().map(([fieldName, object]) => (
              <div className="error first-letter-capitalize" key={fieldName}>
                {_.replace(fieldName, '_', ' ')} {object.first()}
              </div>
            ))}

          <button type="submit" className="button submit-button" disabled={this.isPristine()}>
            {platform.get('id')
              ? saving
                ? 'Updating...'
                : 'Update'
              : saving
              ? 'Adding...'
              : 'Add'}
          </button>
          <button type="cancel" className="button" onClick={this.handleCancel}>
            Cancel
          </button>
          {platform.get('id') && (
            <button
              type="button"
              disabled={updatingActiveStatus}
              className={`button ${
                platform.get('is_active') ? 'button-secondary' : 'button-inverse'
              } pull-right`}
              onClick={this.handleDisable}
            >
              {platform.get('is_active')
                ? updatingActiveStatus
                  ? 'Disabling...'
                  : 'Disable'
                : updatingActiveStatus
                ? 'Enabling...'
                : 'Enable'}
            </button>
          )}
        </form>
      </div>
    )
  }
}

export default withRouter(HotelPlatform)
