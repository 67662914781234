/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { is, fromJS, Map } from 'immutable'
import alertify from 'alertifyjs'
import Select from 'react-select'
import _ from 'lodash'
import {
  loadPermissions,
  loadUserPermissions,
  updateUserPermissions,
  loadUserPropertiesPermissions,
  updateUserPropertiesPermissions,
  deleteUserPropertiesPermissions
} from '../actions/AccountActions'

import Loader from './Loader'
import ToggleShowPassword from './ToggleShowPassword'
import {
  loadHotelStaff,
  updateHotelUser,
  updateHotelUserPassword,
  updateHotelUserRole
} from '../actions/HotelActions'

class EditHotelUser extends React.Component {
  static propTypes = {
    user: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      user: this.getUserProps(props),
      rolesList: fromJS(['account_admin', 'hotel_admin', 'central_staff', 'staff']),
      password: '',
      confirmPassword: '',
      showPassword: false,
      userPermissions: [],
      userPropertiesPermissions: []
    }
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    const { selectedHotelId, selectedAccountId } = match && match.params
    const { hotelUserId } = match && match.params

    this.setState({ selectedAccountId })

    dispatch(loadHotelStaff(selectedHotelId))

    dispatch(loadPermissions())

    dispatch(
      loadUserPermissions(hotelUserId, (statusCode, body) => {
        if (statusCode === 200) {
          this.setState({ userPermissions: body.user_permissions })
        }
      })
    )

    dispatch(
      loadUserPropertiesPermissions(hotelUserId, (statusCode, body) => {
        if (statusCode === 200) {
          this.setState({ userPropertiesPermissions: body.user_properties_permissions })
        }
      })
    )
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      user: this.getUserProps(nextProps)
    })
  }

  getUserProps = props => {
    const { match, hotelUsers } = props
    const { hotelUserId } = match && match.params

    return hotelUsers.get(hotelUserId)
  }

  handleChange = ev => {
    const user = this.state.user.set(ev.target.name, ev.target.value)
    this.setState({ user })
  }

  handleSubmit = ev => {
    ev.preventDefault()

    const { dispatch, match } = this.props
    const { hotelUserId } = match && match.params
    const { user } = this.state

    this.setState({ isSaving: true, error: null })
    dispatch(
      updateHotelUser(hotelUserId, user.toJS(), (statusCode, body) => {
        this.setState({ isSaving: false })
        if (statusCode !== 200) {
          return this.setState({ error: fromJS(body) })
        }

        alertify.success('User successfully updated.')
      })
    )
  }

  handlePasswordChange = ev => {
    ev.preventDefault()
    this.setState({ [ev.target.name]: ev.target.value })
  }

  handlePasswordUpdate = ev => {
    ev.preventDefault()

    const { dispatch, match } = this.props
    const { selectedHotelId, hotelUserId } = match && match.params
    const { password, confirmPassword } = this.state

    const params = {
      password: password,
      password_confirmation: confirmPassword
    }

    this.setState({ isSavingPassword: true, errorUpdatingPassword: null })
    dispatch(
      updateHotelUserPassword(selectedHotelId, hotelUserId, params, (statusCode, body) => {
        this.setState({ isSavingPassword: false })
        if (statusCode !== 200) {
          return this.setState({ errorUpdatingPassword: body && body.error })
        }

        this.setState({ password: '', confirmPassword: '' })
        alertify.success('User password successfully updated.')
        this.handleCancel()
      })
    )
  }

  updatePermissionAction = (permissionId, action) => ev => {
    const { userPermissions } = this.state

    userPermissions.forEach(permission => {
      if (permission.permission_id === permissionId) {
        permission[action] = !permission[action]
      }
    })
  }

  handleSelectAll = event => {
    const { checked } = event.target
    const { hotels, dispatch } = this.props
    const { user, selectedAccountId } = this.state

    const orderedHotels = hotels
      .toList()
      .filter(hotel => hotel.get('account_id') === selectedAccountId)
      .sortBy(hotel => hotel.get('name'))
    const allHotelIds = orderedHotels.map(hotel => hotel.get('id'))

    if (checked) {
      this.setState({ userPropertiesPermissions: allHotelIds }, () => {
        allHotelIds.forEach(hotelId => {
          this.updatePropertiesPermission(hotelId)({ target: { checked: true } })
        })
      })
    } else {
      dispatch(deleteUserPropertiesPermissions(user.get('id')))
      this.setState({ userPropertiesPermissions: [] })
    }
  }

  updatePropertiesPermission = hotelId => ev => {
    const { userPropertiesPermissions } = this.state

    if (ev.target.checked) {
      this.setState({
        userPropertiesPermissions: [...userPropertiesPermissions, hotelId]
      })
    } else {
      this.setState({
        userPropertiesPermissions: userPropertiesPermissions.filter(id => id !== hotelId)
      })
    }
  }

  handlePermissionUpdate = ev => {
    ev.preventDefault()

    const { dispatch } = this.props
    const { userPermissions, user } = this.state

    this.setState({ isSavingPermission: true, errorUpdatingPermission: null })
    dispatch(
      updateUserPermissions(userPermissions, (statusCode, body) => {
        this.setState({ isSavingPermission: false })
        if (statusCode !== 201) {
          return this.setState({ errorUpdatingPermission: body && body.error })
        }

        this.setState({ userPermissions: body.user_permissions })
        alertify.success('User permission successfully updated.')
      })
    )
  }

  handlePropertiesPermissionUpdate = ev => {
    ev.preventDefault()

    const { dispatch } = this.props
    const { userPropertiesPermissions, user } = this.state

    const data = {
      properties_permission: userPropertiesPermissions
    }

    this.setState({ isSavingPropertiesPermission: true, errorUpdatingPropertiesPermission: null })
    dispatch(
      updateUserPropertiesPermissions(data, user.get('id'), (statusCode, body) => {
        this.setState({ isSavingPropertiesPermission: false })
        if (statusCode !== 200) {
          return this.setState({ errorUpdatingPropertiesPermission: body && body.error })
        }

        this.setState({ userPropertiesPermissions: body.user_properties_permissions })
        alertify.success('User properties permission successfully updated.')
      })
    )
  }

  handleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  isAllSelected = () => {
    const { userPropertiesPermissions, selectedAccountId } = this.state
    const { hotels } = this.props

    const orderedHotels = hotels
      .toList()
      .filter(hotel => hotel.get('account_id') === selectedAccountId)
      .sortBy(hotel => hotel.get('name'))
    const allHotelIds = orderedHotels.map(hotel => hotel.get('id'))

    const allSelected = allHotelIds.every(id => userPropertiesPermissions.includes(id))
    return allSelected
  }

  onSelectStaffRole = option => {
    const { dispatch, hotels } = this.props
    const { user, selectedAccountId } = this.state

    const data = {
      roles: [option && option.value]
    }

    this.setState({ updatingRole: true, errorUpdatingRole: null })
    dispatch(
      updateHotelUserRole(user.get('id'), data, (statusCode, body) => {
        this.setState({ updatingRole: false })
        if (statusCode !== 200) {
          return this.setState({ errorUpdatingRole: body && body.error })
        }

        alertify.success('User role successfully updated.')

        if (option.value === 'account_admin') {
          const orderedHotels = hotels
            .toList()
            .filter(hotel => hotel.get('account_id') === selectedAccountId)
            .sortBy(hotel => hotel.get('name'))

          const allHotelIds = orderedHotels.map(hotel => hotel.get('id'))
          this.setState({ userPropertiesPermissions: allHotelIds })
        } else {
          dispatch(deleteUserPropertiesPermissions(user.get('id')))
          this.setState({ userPropertiesPermissions: [] })
        }
      })
    )
  }

  handleCancel = ev => {
    ev.preventDefault()

    const { match, history } = this.props
    const { selectedAccountId, selectedHotelId } = match && match.params
    history.push(`/account/${selectedAccountId}/hotel/${selectedHotelId}/staff`)
  }

  handleReset = ev => {
    ev.preventDefault()

    this.setState({
      user: this.getUserProps(this.props),
      password: '',
      confirmPassword: ''
    })
  }

  isPristine = () => {
    return is(this.state.user, this.getUserProps(this.props))
  }

  render() {
    const { isLoadingHotelUsers, permissions, hotels } = this.props
    const {
      user,
      password,
      confirmPassword,
      isSaving,
      isSavingPassword,
      isSavingPermission,
      isSavingPropertiesPermission,
      showPassword,
      error,
      errorUpdatingPassword,
      rolesList,
      updatingRole,
      errorUpdatingRole,
      userPermissions,
      selectedAccountId,
      userPropertiesPermissions
    } = this.state

    if (!user) return <div />

    let mappedPermissions = []

    if (permissions) {
      mappedPermissions = permissions.toList().sortBy(team => _.toLower(team.get('name')))
    }

    userPermissions.sort((firstPosition, secondPosition) => {
      const firstPermissionName = firstPosition.name.toLowerCase()
      const secondPermissionName = secondPosition.name.toLowerCase()

      if (firstPermissionName < secondPermissionName) {
        return -1 //firstPermissionName is considered "comes before" secondPermissionName
      } else if (firstPermissionName > secondPermissionName) {
        return 1 //firstPermissionName is considered "comes after" secondPermissionName
      } else {
        return 0 //This is returned when firstPermissionName is considered equal to secondPermissionName
      }
    })

    const userRoles = rolesList.map(
      role => new Map({ value: role, label: _.capitalize(role.replace('_', ' ')) })
    )
    const userRole = (user.get('roles') && user.get('roles').first()) || new Map()
    const selectedUserRole = userRoles
      .filter(role => role.get('value') === userRole.get('name'))
      .first()

    const orderedHotels =
      hotels &&
      hotels
        .toList()
        .filter(hotel => hotel.get('account_id') === selectedAccountId)
        .sortBy(hotel => hotel.get('name'))

    return (
      <div className="hotel-staff-account-settings">
        <Loader isLoading={isLoadingHotelUsers} />
        <form disabled={isSaving} onSubmit={this.handleSubmit}>
          <div className="column-layout">
            <div className="account-info">
              <div className="name">
                <input
                  disabled={isSaving}
                  type="text"
                  name="first_name"
                  value={user.get('first_name') || ''}
                  placeholder="First name..."
                  onChange={this.handleChange}
                />
                <input
                  disabled={isSaving}
                  type="text"
                  name="last_name"
                  value={user.get('last_name') || ''}
                  placeholder="Last name..."
                  onChange={this.handleChange}
                />

                <span className="title-span-left">(</span>
                <input
                  disabled={isSaving}
                  type="text"
                  name="title"
                  value={user.get('title') || ''}
                  placeholder="Title..."
                  onChange={this.handleChange}
                />
                <span>)</span>
              </div>

              <input
                disabled
                type="text"
                className="w-60pct"
                name="email"
                value={user.get('email') || ''}
                placeholder="Email address..."
                onChange={this.handleChange}
              />
              <input
                disabled={isSaving}
                className="w-60pct"
                type="text"
                name="phone"
                value={user.get('phone') || ''}
                placeholder="Mobile number..."
                onChange={this.handleChange}
              />
            </div>
          </div>

          {error && (
            <fieldset id="errors">
              {error.entrySeq().map(([key, value]) => (
                <div key={key} className="error">
                  <span>{value}.</span>
                </div>
              ))}
            </fieldset>
          )}

          <div className="actions">
            <button disabled={this.isPristine() || isSaving} type="submit" className="button">
              Save Changes
            </button>
            <button disabled={isSaving} type="cancel" className="button" onClick={this.handleReset}>
              Reset
            </button>
          </div>
        </form>

        <div>
          <h2 className="sub-section-heading">Change User Password</h2>
          <small>Password should be minimum 8 characters. </small>
          <form disabled={isSavingPassword}>
            <div className="w-60pct tmargin1em">
              <input
                disabled={isSavingPassword}
                type="password"
                name="password"
                value={password}
                placeholder="Password"
                onChange={this.handlePasswordChange}
              />
              <input
                disabled={isSavingPassword}
                type={showPassword ? 'text' : 'password'}
                name="confirmPassword"
                value={confirmPassword}
                placeholder="Confirm password"
                onChange={this.handlePasswordChange}
              />
              {this.state.confirmPassword ? (
                <ToggleShowPassword
                  showPassword={showPassword}
                  handleShowPassword={this.handleShowPassword}
                />
              ) : null}
            </div>
            <div className="actions">
              <button
                className="button"
                onClick={this.handlePasswordUpdate}
                disabled={
                  this.state.password !== this.state.confirmPassword ||
                  !this.state.password ||
                  this.state.password.length < 8 ||
                  isSavingPassword
                }
              >
                Update
              </button>
              <button
                disabled={isSaving}
                type="cancel"
                className="button"
                onClick={this.handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>

          {errorUpdatingPassword && <div className="error">{errorUpdatingPassword}</div>}
        </div>

        <div className="w-80pct bmargin5em">
          <h2 className="sub-section-heading bmargin0-5em">Update User Role</h2>
          <fieldset className={updatingRole ? 'custom-disabled' : ''}>
            <Select
              name="user-role"
              options={userRoles && userRoles.toJS()}
              value={selectedUserRole && selectedUserRole.toJS()}
              onChange={this.onSelectStaffRole}
              placeholder={'Select user role...'}
            />
          </fieldset>

          {selectedUserRole && selectedUserRole.get('value') === 'account_admin' && (
            <div className="user-permissions">
              <div className="permissions">
                <table>
                  <tbody>
                    <tr>
                      <td>Property</td>
                      <td>Can access?</td>
                      <td>
                        Select All
                        <input
                          type="checkbox"
                          onChange={this.handleSelectAll}
                          checked={this.isAllSelected()}
                          disabled={isSavingPropertiesPermission}
                          style={{ marginLeft: '5px' }}
                        />
                      </td>
                    </tr>
                    {orderedHotels.map((hotel, i) => (
                      <tr key={i}>
                        <td>{hotel.get('name')}</td>
                        <td>
                          <input
                            type="checkbox"
                            onChange={this.updatePropertiesPermission(hotel.get('id'))}
                            checked={
                              userPropertiesPermissions &&
                              userPropertiesPermissions.includes(hotel.get('id'))
                            }
                            disabled={isSavingPropertiesPermission}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="actions">
                <button
                  className="button"
                  onClick={this.handlePropertiesPermissionUpdate}
                  disabled={
                    isSavingPropertiesPermission ||
                    this.state.userPropertiesPermissions.length === 0
                  }
                >
                  Update Properties Access
                </button>
              </div>
            </div>
          )}

          {errorUpdatingRole && <div className="error">{errorUpdatingRole}</div>}
        </div>

        <div className="w-80pct bmargin5em">
          <h2 className="sub-section-heading bmargin0-5em">Update User Permissions</h2>

          <div className="user-permissions">
            <div className="permissions">
              <table>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>List</td>
                    <td>Create</td>
                    <td>Edit</td>
                    <td>Delete</td>
                  </tr>
                  {userPermissions.map((userPermission, i) => (
                    <tr key={i}>
                      <td>{userPermission.name}</td>
                      <td>
                        <input
                          type="checkbox"
                          onChange={this.updatePermissionAction(
                            userPermission.permission_id,
                            'get'
                          )}
                          defaultChecked={userPermission.get}
                          disabled={isSavingPermission}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          onChange={this.updatePermissionAction(
                            userPermission.permission_id,
                            'post'
                          )}
                          defaultChecked={userPermission.post}
                          disabled={isSavingPermission}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          onChange={this.updatePermissionAction(
                            userPermission.permission_id,
                            'put'
                          )}
                          defaultChecked={userPermission.put}
                          disabled={isSavingPermission}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          onChange={this.updatePermissionAction(
                            userPermission.permission_id,
                            'remove'
                          )}
                          defaultChecked={userPermission.remove}
                          disabled={isSavingPermission}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="actions">
            <button
              className="button"
              onClick={this.handlePermissionUpdate}
              disabled={isSavingPermission}
            >
              Update Permissions
            </button>
          </div>

          {errorUpdatingRole && <div className="error">{errorUpdatingRole}</div>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  hotelUsers: state.hotelUsers,
  isLoadingHotelUsers: state.loader.get('isLoadingHotelUsers'),
  permissions: state.permissions,
  accounts: state.accounts,
  hotels: state.hotels
})

export default withRouter(connect(mapStateToProps)(EditHotelUser))
