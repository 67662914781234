import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const { RECEIVE_GET_ACCOUNT_ADMINS, RECEIVE_GET_ACCOUNT_ADMIN } = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data } = action

  switch (type) {
    case RECEIVE_GET_ACCOUNT_ADMINS:
      const accountAdmins = new OrderedMap(
        data.account_admins.map(setting => [setting.id, fromJS(setting)])
      )
      return state.merge(accountAdmins)

    case RECEIVE_GET_ACCOUNT_ADMIN:
      return state.merge(fromJS({ [data.user.id]: data.user }))

    default:
      return state
  }
}
