import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const { RECEIVE_GET_WRONG_IN_HOUSE_GUESTS, RECEIVE_CHECKOUT_WRONG_IN_HOUSE_GUESTS } = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data } = action

  switch (type) {
    case RECEIVE_GET_WRONG_IN_HOUSE_GUESTS:
      const newGuests = new OrderedMap(
        data.wrong_in_house_guests.map(guest => [guest.id, fromJS(guest)])
      )
      return state.concat(newGuests)

    case RECEIVE_CHECKOUT_WRONG_IN_HOUSE_GUESTS:
      return data.checkouts.reduce((updatedState, id) => updatedState.remove(id), state)

    default:
      return state
  }
}
