/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

import { Map } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const {
  REQUEST_SET_LOADING_STATE,
  RECEIVE_LOADING_HOTEL_USERS,
  RECEIVE_LOADING_WRONG_IN_HOUSE_GUESTS
} = ActionTypes

const initialState = new Map({
  isLoadingMain: false
})

export default (state = initialState, action) => {
  const { type, data } = action

  switch (type) {
    case REQUEST_SET_LOADING_STATE:
      return state.set('isLoadingMain', data)

    case RECEIVE_LOADING_HOTEL_USERS:
      return state.set('isLoadingHotelUsers', data)

    case RECEIVE_LOADING_WRONG_IN_HOUSE_GUESTS:
      return state.set('isLoadingWrongInHouseGuests', data)

    default:
      return state
  }
}
